import React, { Component } from 'react';
// import './App.css';
import instance from "./axios-instance";
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
//import './style.css';
import NavMenu from './navmenu';
import Systems from "./SystemFunction";
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categorylist: [],
      rewardlist: [],
      activePage: 1,
      formData: {}, // Contains login form data
      errors: {}, // Contains login field errors
      formSubmitted: false, // Indicates submit status of login form
      loading: false, // Indicates in progress state of login form
      pagecount: 1,
      playerid: null,
      user_id: null,
      point: null,
      products_page_count: 1,
      loginstate: false,
      firstname: null,
      lastname: null,
      alert: null,
    }
    this.handleClick = this.handleClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.hideAlert = this.hideAlert.bind(this);

  }
  handlePageChange(pageNumber) {
    //////console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });
    instance
      .get("/api/v1/rewardlist", {
        params: {
          Cat_ID: 0,
          page: pageNumber
        }
      })
      .then((res) => {
        this.setState({
          rewardlist: res.data.message.products,
          pagecount: res.data.message.pagecount,
          products_page_count: res.data.message.products_page_count
        });
      });
  }

  handleClick = async (e) => {
    this.setState({
      activePage: 1,
      isActive: true,
      activeTab: 0
    });
    instance.get("/api/v1/rewardlist", {
      params: {
        Cat_ID: e.target.id,
        page: 1
      }
    })
      .then((res) => {
        this.setState({
          rewardlist: res.data.message.products,
          pagecount: res.data.message.pagecount,
          products_page_count: res.data.message.products_page_count
        });
      });
    this.setState({
      isActive: false
    });
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }



  redeem = (e) => {
    e.preventDefault();
    this.checklogin();
  }

  checklogin() {
    var token = localStorage.getItem('auth_token');
    if (token != null) {
      var decoded = jwt_decode(token);
      this.setState({
        user_id: decoded.message.playerid,
        playerid: decoded.message.member_id,
        firstname: decoded.message.firstname,
        lastname: decoded.message.lastname,
        point: decoded.message.Point,
        loginstate: true,
      });
    }
    else {
      this.setState({
        loginstate: false,
      });
    }
  }

  async componentDidMount() {
    this.checklogin();
    const cacheExpiry = 6 * 60 * 60 * 1000; // 6 ชั่วโมงในมิลลิวินาที
    const currentTime = new Date().getTime();
    const cachedCategoryList = localStorage.getItem('categorylist_8');
    const categoryTimestamp = localStorage.getItem('categoryTimestamp_8');

    if (cachedCategoryList && categoryTimestamp && (currentTime - categoryTimestamp < cacheExpiry)) {
      // ใช้ข้อมูลจากแคช
      this.setState({
        categorylist: JSON.parse(cachedCategoryList)
      });
    } else {
      // เรียก API หากไม่มีแคชหรือแคชหมดอายุ
      await instance.post("/api/v1/tabgamelist", {
        state: "8",
        System: Systems,
      })
        .then((res) => {
          if (res.data.status === 200) {
            this.setState({
              categorylist: res.data.message
            });
            // บันทึกข้อมูลลงแคช
            localStorage.setItem('categorylist_8', JSON.stringify(res.data.message));
            localStorage.setItem('categoryTimestamp_8', currentTime);
          } else {
            console.error("Failed to fetch category list.");
          }
        }).catch((error) => {
          console.error("Error fetching category list:", error);
        });
    }


  }
  render() {
    return (
  <>
      <div className="d-lg-none">
            <div className="x-feed-news-header">
              <div className="-feed-news-inner-wrapper" data-delat={200}>
                <div className="-icon-container">
                  <i className="fas fa-volume-up" />
                </div>
                <div className="-track">
                  <div className="-track-item -duration-normal-content">
                    Ox88 Casino Online อันดับ 1 ในไทย สล๊อตเว็บตรง ฝาก-ถอน Auto มั่นคงปลอดภัย บริการ 24 ชั่วโมง
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="-games-provider-wrapper" >
            <NavMenu />
            <div className="-games-index-page " >
              <h2 className="-heading-sub-title">
                Ox88 Casino คาสิโนออนไลน์เต็มรูปแบบ ตื่นตาตื่นใจไปกับทุกการเดิมพัน
              </h2>
              <div className="x-category-provider js-game-scroll-container js-game-container">
                <div className="-games-list-container container">
                  <nav className="nav-menu" id="navbarProvider">
                    <ul className="nav nav-pills">
                      {this.state.categorylist.map((listitem, i) => (
                        <li key={listitem.i} id={listitem.gameid} className="nav-item -game-casino-macro-container">
                          <a
                            href={"/listgameall/fishing/" + listitem.partner}
                            className="nav-link js-account-approve-aware"
                            rel="nofollow noopener"
                          >
                            <div className="x-game-list-item-macro js-game-list-toggle -big " data-status>
                              <div className="-inner-wrapper">
                                <picture>
                                  <img src={listitem.image} className="-cover-img lazyload img-fluid" alt="dream-gaming" width={364} height={231} />
                                </picture>
                                <div className="-overlay">
                                  <div className="-overlay-inner">
                                    <div className="-wrapper-container">
                                      <button href={"/listgameall/fishing/" + listitem.partner} className="-btn -btn-play js-account-approve-aware"  >
                                        <i className="fas fa-play" />
                                        <span className="-text-btn">เข้าเล่น</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="-title">{listitem.description}</div>
                            </div>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

          </div>
  </>


    );
  }
}
export default App;
