import React, { Component } from 'react'; //different
import './App.css';

class App extends Component {


    onClick = (e) => {
        e.preventDefault()
        ////console.log('onclick..')
    }
    render() {
        return (
            <footer className="x-footer ">
                <div className="container-fluid -footer-wrapper">
                    <div className="-footer-inner-wrapper">
                        <div className="-describe-wrapper">
                            <div className="-content-wrapper">
                                <h6 className="-title">
                                    Ae sexyCASINO th เว็บคาสิโนออนไลน์ <br /> ครองอันดับในใจคนไทย 3 ปีซ้อน
                                </h6>
                                <h6 className="-content">
                                    Ae sexyCasino บาคาร่า คาสิโนออนไลน์ ที่ดีที่สุด
                                    เพื่อประสบการณ์ที่ดีของผู้เล่นอย่างแท้จริง แบบAe sexyCasino
                                </h6>
                            </div>
                        </div>
                        <div className="-tag-wrapper">
                            <div className="-content-wrapper">
                                <div className="-title">TAG</div>
                                <div className="container x-footer-seo">
                                    <div className="-tags">
                                        <a href="/" className="btn">
                                            Ae sexy
                                        </a>
                                        <a href="/" className="btn">
                                            Ae sexy casino
                                        </a>
                                        <a href="/" className="btn">
                                            Ae sexycasino
                                        </a>
                                        <a href="/" className="btn">
                                            Ae sexyคาสิโน
                                        </a>
                                        <a href="/" className="btn">
                                            Ae sexyคาสิโนออนไลน์
                                        </a>
                                        <a href="/" className="btn">
                                            Ae sexyบาคาร่า
                                        </a>
                                        <a href="/" className="btn">
                                            Ae sexybet
                                        </a>
                                        <a href="/" className="btn">
                                            Ae sexy 55
                                        </a>
                                        <a href="/" className="btn">
                                            Ae sexy bet444
                                        </a>
                                        <a href="/" className="btn">
                                            Ae sexygaming
                                        </a>
                                        <a href="/" className="btn">
                                            Ae sexycasino gaming
                                        </a>
                                        <a href="/" className="btn">
                                            คาสิโนสด
                                        </a>
                                        <a href="/" className="btn">
                                            bet online
                                        </a>
                                        <a href="/" className="btn">
                                            Ae sexylive casino
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="-provider-wrapper">
                            <div className="px-3">
                                <div
                                    className="x-contact-directly"
                                    data-animatable="fadeInUp"
                                    data-delay={100}
                                >
                                    <div className="-text-wrapper -empty">
                                    </div>
                                    <div className="-qr-wrapper -empty"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="-footer-bottom-wrapper">
                        <div>
                            <a
                                href="/termcondition"
                                target="_blank"
                                className="-term-btn"
                            >
                                Terms and Conditions
                            </a>
                        </div>
                        <div>
                            <p className="mb-0">Copyright © 2021Ae sexyCasino All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>

        );
    }
}
export default App;
