import React, { Component } from 'react'; //different
import SweetAlert from 'react-bootstrap-sweetalert';
//import Paoyingchub from './game/paoyingchub';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
// import './style.css';
import System from "./SystemFunction";
import NavMenu from './navmenu';
import Listmenu from './listmenu';
import Systems from "./SystemFunction";
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            rewardlist: [],
            activePage: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            playerid: null,
            user_id: null,
            point: null,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
        }

    }

    redeem = (e) => {
        e.preventDefault();
        this.checklogin();
        //////console.log('tag', e.target.href);
        if (!this.state.loginstate) {
            var title = 'Not login!'
            var text = ' คุณไม่ได้ทำการ Login'
            var object = { title, text }
        }
    }


    checklogin() {
        var token = localStorage.getItem('auth_token');
       if (token != null) {
            var decoded = jwt_decode(token);
            this.setState({
                user_id: decoded.message.playerid,
                playerid: decoded.message.member_id,
                firstname: decoded.message.firstname,
                lastname: decoded.message.lastname,
                point: decoded.message.Point,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }


    async componentDidMount() {
        try {
            const cacheExpiry = 6 * 60 * 60 * 1000; // 6 ชั่วโมงในมิลลิวินาที
            const currentTime = new Date().getTime();
            const cachedCategoryList = localStorage.getItem('categorylist');
            const categoryTimestamp = localStorage.getItem('categoryTimestamp');
        
            if (cachedCategoryList && categoryTimestamp && (currentTime - categoryTimestamp < cacheExpiry)) {
                this.setState({
                    categorylist: JSON.parse(cachedCategoryList)
                });
            } else {
                this.checklogin();
                await instance.post("/api/v1/tabgamelist", {
                    state: "2",
                    System: Systems,
                }).then((res) => {
                    if (res.data.status === 200) {
                        this.setState({
                            categorylist: res.data.message
                        });
                        // บันทึกข้อมูลลงแคช
                        localStorage.setItem('categorylist', JSON.stringify(res.data.message));
                        localStorage.setItem('categoryTimestamp', currentTime);
                    } else {
                        // การจัดการเมื่อ API ล้มเหลว
                    }
                });
            }
        } catch (ex) {
            console.error("Error occurred:", ex);
        }
        

    }

    render() {

        return (
            <>
             <div className="d-lg-none">
                        <div className="x-feed-news-header">
                            <div className="-feed-news-inner-wrapper" data-delat={200}>
                                <div className="-icon-container">
                                    <i className="fas fa-volume-up" />
                                </div>
                                <div className="-track">
                                    <div className="-track-item -duration-normal-content">
                                       Ox88 Casino Online อันดับ 1 ในไทย สล๊อตเว็บตรง ฝาก-ถอน Auto มั่นคงปลอดภัย บริการ 24 ชั่วโมง
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="-games-provider-wrapper" >
                        <NavMenu />
                        <div className="-games-index-page lazyloaded" >
                            <h2 className="-heading-sub-title">Ox88  Casino คาสิโนออนไลน์เต็มรูปแบบ ตื่นตาตื่นใจไปกับทุกการเดิมพัน</h2>
                            <div className="x-category-provider js-game-scroll-container js-game-container ">
                                <div className="-games-list-container container">
                                    <nav className="nav-menu" id="navbarProvider">
                                        <ul className="nav nav-pills">
                                            {this.state.categorylist.map((listitem, i) => (
                                                <li key={i} id={listitem.gameid} className="nav-item -game-casino-macro-container">
                                                    <a href={"/listgameall/casino/" + listitem.partner}
                                                        className="nav-link js-account-approve-aware"
                                                        rel="nofollow noopener">
                                                        <div className="x-game-list-item-macro js-game-list-toggle -big " data-status>
                                                            <div className="-inner-wrapper">
                                                                <picture>
                                                                    <img src={listitem.image} className="-cover-img lazyload img-fluid" alt="dream-gaming" width={364} height={231} />
                                                                </picture>
                                                                <div className="-overlay">
                                                                    <div className="-overlay-inner">
                                                                        <div className="-wrapper-container">
                                                                            <button href={"/listgameall/casino/" + listitem.partner} className="-btn -btn-play js-account-approve-aware"  >
                                                                                <i className="fas fa-play" />
                                                                                <span className="-text-btn">เข้าเล่น</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="-title">{listitem.description}</div>
                                                        </div>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
            </>

        );

    }
}
export default App;
