import React, { Component } from 'react'; //different
//import './style.css';
import NavMenu from './navmenu';
import Lowmenu from './lowmenu';
import Listmenu from './listmenu';
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
import Casino from './casino';
import Slot from './slot';
import Footer from './footer';
import Arcade from './arcade';
import Sport from './sport';
import Fishing from './fising';
import Listgameall from "./linklistall";
import Swal from 'sweetalert2'
import { isMobile } from 'react-device-detect';
import Systems from "./SystemFunction";
import md5 from 'md5';
import Sidebar from './sidebar';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            slotlist: [],
            sportlist: [],
            recentlist: [],
            fishinglist: [],
            rewardlist: [],
            activePage: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            recentmenu: false,
            playerid: null,
            username: null,
            user_id: null,
            point: null,
            userid: null,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
        }
        this.handleClick = this.handleClick.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    }
    async saveclick(linkid) {
        await instance.post("/api/v1/saveclick", {
            linkid: linkid,
            System: Systems,
        }).then((res) => {
        });

    }

    handlePageChange(pageNumber) {
        //////console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
        instance
            .get("/api/v1/rewardlist", {
                params: {
                    Cat_ID: 0,
                    page: pageNumber
                }
            })
            .then((res) => {
                this.setState({
                    rewardlist: res.data.message.products,
                    pagecount: res.data.message.pagecount,
                    products_page_count: res.data.message.products_page_count
                });
            });
    }

    redeem = (e, gameIdx, partner) => {
        var userAgent = navigator.userAgent
        this.checklogin();
        if (!this.state.loginstate) {
            return document.getElementById("loginmode").click();
        }
        if (this.state.username !== null) {
            instance.post("/api/v1/tablauncher", {
                gameId: gameIdx,
                partner: partner,
                playerUsername: this.state.username,
                isMobile: isMobile,
                System: Systems,
                demo: false
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        //////console.log(res.data.message);
                        var test = res.data.message.launcher
                        if (test !== undefined) {
                            if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
                                window.location.href = test.mobile;
                            }
                            else {
                                if (!isMobile) window.open(test.desktop, '_blank', 'height=1000,width=1000');
                                else window.open(test.mobile);
                            }
                        }
                        else if (test === undefined) {
                            Swal.fire({
                                title: 'error!',
                                text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                    }
                    else {
                        //  this.CheckThisGoalError();
                    }
                });
        }
        else {

        }


    }

    handleClick = async (e) => {
        this.setState({
            activePage: 1,
            isActive: true,
            activeTab: 0
        });
        instance.get("/api/v1/rewardlist", {
            params: {
                Cat_ID: e.target.id,
                page: 1
            }
        })
            .then((res) => {
                this.setState({
                    rewardlist: res.data.message.products,
                    pagecount: res.data.message.pagecount,
                    products_page_count: res.data.message.products_page_count
                });
            });
        this.setState({
            isActive: false
        });
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }

    addDefaultSrc(ev, listitem) {
        const origin = listitem.split("http");
        //  console.log("img", origin.length)
        if (origin.length > 1) {
            return listitem;
        }
        else {
            return "https://ardmhzelxcmj.ocrazeckyunc.com" + listitem

        }

    }

    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            this.rechecklogin(Username, token);
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }

    async rechecklogin(Username, token) {
        try {
            //console.log(1, Username, Systems, token);
            const search = window.location.href;
            const paramx = new URLSearchParams(search);
            const space = window.location.host
            var userhash = md5(Username);
            const linkaff = "https://" + space + "/register?reg=" + userhash;
            instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'rechecklogin-Main.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //  console.log(181,res.data);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        tblnextrank: res.data.tblnextrank,
                        tblprofile: res.data.message,
                        linkaff: linkaff,
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        fullname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                }
                else if (res.data.status === 401) {
                    //  console.log(res.data);
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                // console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }

    }

    async componentDidMount() {
        const search = window.location.href;
        var test = search.split("aff=")[1]
        // console.log(test);
        if (test != undefined) {
            this.saveclick(test)
        }

        var Username;
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            Username = decoded.message.playerid;
        }
        const pathname = window.location.pathname.split("/");

        if (pathname.length > 1) {
            this.setState({
                pathname: pathname[1]
            });
            console.log(pathname);
            if (pathname[1] == "casino") {
                this.setState({
                    tabselect: 10,
                });
            }
            else if (pathname[1] == "gamehit") {

                this.setState({
                    tabselect: 11,
                });
            }
            else if (pathname[1] == "fishing") {
                this.setState({
                    tabselect: 12,
                });
            }
            else if (pathname[1] == "loss") {
                this.setState({
                    tabselect: 25,
                });
            }
            else if (pathname[1] == "slot") {
                this.setState({
                    tabselect: 13,
                });
            }
            else if (pathname[1] == "register") {
                this.setState({
                    tabselect: 22,
                });
            }
            else if (pathname[1] == "signin") {
                this.setState({
                    tabselect: 23,
                });
            }
            else if (pathname[1] == "sport") {
                this.setState({
                    tabselect: 14,
                });
            }
            else if (pathname[1] == "event") {
                this.setState({
                    tabselect: 16,
                });
            }
            else if (pathname[1] == "luckyfortune") {
                this.setState({
                    tabselect: 35,
                });
            }
            else if (pathname[1] == "lotto") {
                this.setState({
                    tabselect: 17,
                });
            }
            else if (pathname[1] == "withdraw") {
                // console.log(1232323)
                if (this.state.loginstate) {
                    this.setState({
                        tabselect: 2,
                    });
                }
                else {
                    this.setState({
                        tabselect: 0,
                    });
                }
            }
            else if (pathname[1] == "deposit") {
                if (this.state.loginstate) {
                    this.setState({
                        tabselect: 1,
                        tabselectdeposit: 1,
                    });
                }
                else {
                    this.setState({
                        tabselect: 0,
                    });
                }
            }
            else if (pathname[1] == "statement") {
                if (this.state.loginstate) {
                    this.setState({
                        tabselect: 3,
                    });
                }
                else {
                    this.setState({
                        tabselect: 0,
                    });
                }
            }
            else if (pathname[1] == "profile") {
                if (this.state.loginstate) {
                    this.setState({
                        tabselect: 8,
                    });
                }
                else {
                    this.setState({
                        tabselect: 0,
                    });
                }
            }
            else if (pathname[1] + "/" + pathname[2] == "refer/overview") {
                this.setState({
                    tabselect: 4,
                    refer: 1
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "refer/member") {
                console.log(63855555);
                this.setState({
                    tabselect: 4,
                    refer: 2
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "refer/income-summary") {
                this.setState({
                    tabselect: 4,
                    refer: 3
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "refer/share-network") {
                this.setState({
                    tabselect: 4,
                    refer: 4
                });
            }
            else if (pathname[1] == "rebate") {
                this.setState({
                    tabselect: 5,
                });
            }
            else if (pathname[1] == "promotion") {
                this.setState({
                    tabselect: 7,
                });
            }
            else if (pathname[1] == "listgameall") {
                await this.getgame(pathname[2], pathname[3]);
                this.setState({
                    tabselect: 15,
                });
            }
        }
        this.fetchDataWithCache(Username);
        this.checklogin();
    }

    async fetchDataWithCache(Username) {
        const cacheExpiry = 3 * 60 * 60 * 1000; // 3 hours in milliseconds
        const currentTime = new Date().getTime();

        // Get cached data for category, slot, and recent
        const cachedCategory = JSON.parse(localStorage.getItem('categorylist'));
        const cachedSlot = JSON.parse(localStorage.getItem('slotlist'));
        const cachedRecent = JSON.parse(localStorage.getItem('recentlist'));
        const categoryTimestamp = localStorage.getItem('categoryTimestamp');
        const slotTimestamp = localStorage.getItem('slotTimestamp');
        const recentTimestamp = localStorage.getItem('recentTimestamp');

        // Check if cached data is still valid (within 3 hours)
        if (cachedCategory && currentTime - categoryTimestamp < cacheExpiry) {
            this.setState({ categorylist: cachedCategory });
        } else {
            await instance.post("/api/v1/tabgamelistpop", {
                state: "2",
                System: Systems,
            }).then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        categorylist: res.data.message
                    });
                    localStorage.setItem('categorylist', JSON.stringify(res.data.message));
                    localStorage.setItem('categoryTimestamp', currentTime);
                }
            });
        }

        if (cachedSlot && currentTime - slotTimestamp < cacheExpiry) {
            this.setState({ slotlist: cachedSlot });
        } else {
            await instance.post("/api/v1/tabgamelistpop", {
                state: "3",
                System: Systems,
            }).then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        slotlist: res.data.message
                    });
                    localStorage.setItem('slotlist', JSON.stringify(res.data.message));
                    localStorage.setItem('slotTimestamp', currentTime);
                }
            });
        }

        const cacheExpiryx = 5 * 60 * 1000; // 5 นาทีในมิลลิวินาที        
        if (cachedRecent && currentTime - recentTimestamp < cacheExpiryx) {
            this.setState({
                recentlist: cachedRecent,
                recentmenu: true
            });
        } else {
            await instance.post("/api/v1/tabgamelistpop", {
                state: "10",
                System: Systems,
                playerid: Username
            }).then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        recentlist: res.data.message,
                        recentmenu: true
                    });
                    localStorage.setItem('recentlist', JSON.stringify(res.data.message));
                    localStorage.setItem('recentTimestamp', currentTime);
                }
            });
        }



        const cachedfihsingList = localStorage.getItem('fihsinglist_8');
        const fihsingTimestamp = localStorage.getItem('fihsingTimestamp_8');

        if (cachedfihsingList && fihsingTimestamp && (currentTime - fihsingTimestamp < cacheExpiry)) {
            // ใช้ข้อมูลจากแคช
            this.setState({
                fishinglist: JSON.parse(cachedfihsingList)
            });
        } else {
            // เรียก API หากไม่มีแคชหรือแคชหมดอายุ
            await instance.post("/api/v1/tabgamelist", {
                state: "8",
                System: Systems,
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        this.setState({
                            fishinglist: res.data.message
                        });
                        // บันทึกข้อมูลลงแคช
                        localStorage.setItem('fihsinglist_8', JSON.stringify(res.data.message));
                        localStorage.setItem('fihsingTimestamp_8', currentTime);
                    } else {
                        console.error("Failed to fetch category list.");
                    }
                }).catch((error) => {
                    console.error("Error fetching category list:", error);
                });
        }



        const cachedsportList = localStorage.getItem('sportlist_8');
        const sportTimestamp = localStorage.getItem('sportTimestamp_8');

        if (cachedsportList && sportTimestamp && (currentTime - sportTimestamp < cacheExpiry)) {
            // ใช้ข้อมูลจากแคช
            this.setState({
                sportlist: JSON.parse(cachedsportList)
            });
        } else {
            // เรียก API หากไม่มีแคชหรือแคชหมดอายุ
            await instance.post("/api/v1/tabgamelist", {
                state: "5",
                System: Systems,
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        this.setState({
                            sportlist: res.data.message
                        });
                        // บันทึกข้อมูลลงแคช
                        localStorage.setItem('sportlist_8', JSON.stringify(res.data.message));
                        localStorage.setItem('sportTimestamp_8', currentTime);
                    } else {
                        console.error("Failed to fetch category list.");
                    }
                }).catch((error) => {
                    console.error("Error fetching category list:", error);
                });
        }

    }
    checkurl = async (e, url) => {
        window.history.pushState(null, null, url);
        this.setState({
            modalx: false,
            modal: false,
        });
        const pathname = window.location.pathname.split("/")
        console.log(210, pathname);
        if (pathname.length > 1) {

            this.setState({
                pathname: pathname[1],
            });
            if (pathname[1] == "") {
                console.log(250, pathname[1]);
                this.setState({
                    tabselect: 0,
                });
            }
            else if (pathname[1] == "casino") {
                this.setState({
                    tabselect: 10,
                });
            }
            else if (pathname[1] == "gamehit") {
                this.setState({
                    tabselect: 11,
                });
            }
            else if (pathname[1] == "fishing") {
                this.setState({
                    tabselect: 12,
                });
            }
            else if (pathname[1] == "slot") {
                console.log(101, pathname);
                this.setState({
                    tabselect: 13,
                });
            }
            else if (pathname[1] == "sport") {
                this.setState({
                    tabselect: 14,
                });
            }
            else if (pathname[1] == "loss") {
                this.setState({
                    tabselect: 25,
                });
            }

            else if (pathname[1] == "event") {
                this.setState({
                    tabselect: 16,
                });
            }
            else if (pathname[1] == "luckyfortune") {
                this.setState({
                    tabselect: 35,
                });
            }
            else if (pathname[1] == "lotto") {
                this.setState({
                    tabselect: 17,
                });
            }
            else if (pathname[1] == "withdraw") {
                if (this.state.loginstate) {
                    this.setState({
                        tabselect: 2,
                    });
                }
                else {
                    this.setState({
                        tabselect: 0,
                    });
                }
            }
            else if (pathname[1] == "deposit") {
                this.setState({
                    tabselect: 1,
                    tabselectdeposit: 1,
                });
            }
            else if (pathname[1] == "statement") {
                this.setState({
                    tabselect: 3,
                });
            }
            else if (pathname[1] == "profile") {
                this.setState({
                    tabselect: 8,
                });
            }
            else if (pathname[1] == "register") {
                this.setState({
                    tabselect: 22,
                });
            }
            else if (pathname[1] == "signin") {
                this.setState({
                    tabselect: 23,
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "refer/overview") {
                this.setState({
                    tabselect: 4,
                    refer: 1
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "refer/member") {
                this.setState({
                    tabselect: 4,
                    refer: 2
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "refer/income-summary") {
                this.setState({
                    tabselect: 4,
                    refer: 3
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "refer/share-network") {
                this.setState({
                    tabselect: 4,
                    refer: 4
                });
            }
            else if (pathname[1] == "rebate") {
                this.setState({
                    tabselect: 5,
                });
            }
            else if (pathname[1] == "promotion") {
                this.setState({
                    tabselect: 7,
                });
            }
            else if (pathname[1] == "listgameall") {
                await this.getgame(pathname[2], pathname[3]);
                this.setState({
                    tabselect: 15,
                });
            }
        }
        else {
            this.setState({
                pathname: '',
                tabselect: 0,
            });
        }
    }
    async getgame(typepage, partner) {
        await instance.post("/api/v1/tabgamelist", {
            state: "999",
            System: Systems,
            partner: partner,
            game: typepage,
        }).then((res) => {
            console.log(803, res.data);
            if (res.data.status === 200) {
                this.setState({
                    listitallgame: res.data.message
                });
            }
        });
    }
    copyCode = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                this.notify("warning", 'CopyCode!!!');
            })
            .catch((err) => {
                // console.log(err.message);
            });

    };
    notify = async (mode, msg) => {
        console.log(mode, msg);
        if (mode == "success") {
            await toast.success(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "warning") {
            await toast.warning(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "error") {
            await toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "info") {
            await toast.info(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        await this.waitnotify();
    }
    waitnotify = async () => {
        setTimeout(() => { console.log('World!') }, 5000)
    }
    renderTabContent() {
        const pathname = this.state.pathname


        console.log("renderTabContent", pathname, this.state.tabselect);
        switch (this.state.tabselect) {
            case 10:
                return <>
                    <div className="d-lg-none">
                        <div className="x-feed-news-header">
                            <div className="-feed-news-inner-wrapper" data-delat={200}>
                                <div className="-icon-container">
                                    <i className="fas fa-volume-up" />
                                </div>
                                <div className="-track">
                                    <div className="-track-item -duration-normal-content">
                                        Ox88 Casino Online อันดับ 1 ในไทย สล๊อตเว็บตรง ฝาก-ถอน Auto มั่นคงปลอดภัย บริการ 24 ชั่วโมง
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="-games-provider-wrapper" >
                        <div className="-menu-index-page" style={{ position: "relative" }}>
                            <div data-menu-sticky="js-sticky-widget" className="js-sticky-widget sticksy-dummy-node" style={{ overflowX: "hidden" }} >
                                <div className="x-menu-provider js-tab-menu-provider ">
                                    <nav className="nav-menu flex-column" id="navbarCategory">
                                        <ul className="nav nav-pills js-menu-container -nav-menu-container">
                                            <li className="nav-item">
                                                <button
                                                    className={"nav-link -hot-game " + (pathname === "" ? "active" : "")}
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/")
                                                    }}
                                                >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-hot-game.png"
                                                            alt="คาสิโนออนไลน์ บาคาร่า สล๊อตออนไลน์ ยอดนิยม"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">HOT GAME</div>
                                                            <div className="-text-nav-menu -title-trans">ยอดนิยม</div>
                                                            <div className="-text-nav-menu -title-mobile">ยอดนิยม</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={"nav-link -casino " + (pathname === "casino" ? "active" : "")}
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/casino")
                                                    }}
                                                >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-casino.png"
                                                            alt="คาสิโน บาคาร่า รูเล็ต ซิคโบ ไฮโล"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">CASINO</div>
                                                            <div className="-text-nav-menu -title-trans">คาสิโนสด</div>
                                                            <div className="-text-nav-menu -title-mobile">คาสิโน</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button className={"nav-link -slot " + (pathname === "slot" ? "active" : "")}
                                                     onClick={(e) => {
                                                        this.checkurl(e, "/slot")
                                                    }}>
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-slot.png"
                                                            alt="บริการสล็อตออนไลน์ ฝาก 100 รับเครดิตฟรี 100"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">SLOT</div>
                                                            <div className="-text-nav-menu -title-trans">สล็อตเกมส์</div>
                                                            <div className="-text-nav-menu -title-mobile">สล็อต</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button className={"nav-link -sport " + (pathname === "sport" ? "active" : "")}
                                                onClick={(e) => {
                                                    this.checkurl(e, "/sport")
                                                }}
                                                >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-sport.png"
                                                            alt="แทงฟุตบอลพนันออนไลน์ บาสเก็ตบอล E-Sport"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">SPORT</div>
                                                            <div className="-text-nav-menu -title-trans">กีฬา</div>
                                                            <div className="-text-nav-menu -title-mobile">กีฬา</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            <li className="nav-item" >
                                                <button
                                                    className={"nav-link -fishing-game " + (pathname === "fising" ? "active" : "")}
                                             
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/fising")
                                                    }}
                                                >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-fishing-game.png"
                                                            alt="ยิงปลาได้เงินจริง เครดิตฟรีแรกฝาก"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">FISHING</div>
                                                            <div className="-text-nav-menu -title-trans">ยิงปลา</div>
                                                            <div className="-text-nav-menu -title-mobile">ยิงปลา</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                        </ul>
                                        <div className="-contact-wrapper  " >
                                            <div className="x-contact-us -multiple">
                                                <img
                                                    alt=""
                                                    className="img-fluid -cover-img ls-is-cached lazyloaded"
                                                    src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/contact-us-multiple-cover.png"
                                                />
                                                <div className="-btn-group-wrapper">
                                                    <a
                                                        href={this.state.linelink}
                                                        className="-link-wrapper -line-wrapper"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <img
                                                            alt=""
                                                            className="img-fluid -img ls-is-cached lazyloaded"
                                                            src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/contact-us-multiple-line.png"
                                                        />
                                                    </a>
                                                    <a
                                                        href="XXXXXXX"
                                                        className="-link-wrapper -telegram-wrapper"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <img
                                                            alt=""
                                                            className="img-fluid -img ls-is-cached lazyloaded"
                                                            src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/contact-us-multiple-telegram.png"
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                    </nav>
                                </div>
                            </div>

                        </div >
                        <div className="-games-index-page lazyloaded" >
                            <h2 className="-heading-sub-title">Ox88  Casino คาสิโนออนไลน์เต็มรูปแบบ ตื่นตาตื่นใจไปกับทุกการเดิมพัน</h2>
                            <div className="x-category-provider js-game-scroll-container js-game-container ">
                                <div className="-games-list-container container">
                                    <nav className="nav-menu" id="navbarProvider">
                                        <ul className="nav nav-pills">
                                            {this.state.categorylist.map((listitem, i) => (
                                                <li key={i} id={listitem.gameid} className="nav-item -game-casino-macro-container">
                                                    <a
                                                        onClick={(e) => {
                                                            this.checkurl(e, "/listgameall/casino/" + listitem.partner)
                                                        }}
                                                        className="nav-link js-account-approve-aware"
                                                        rel="nofollow noopener">
                                                        <div className="x-game-list-item-macro js-game-list-toggle -big " data-status>
                                                            <div className="-inner-wrapper">
                                                                <picture>
                                                                    <img src={listitem.image} className="-cover-img lazyload img-fluid" alt="dream-gaming" width={364} height={231} />
                                                                </picture>
                                                                <div className="-overlay">
                                                                    <div className="-overlay-inner">
                                                                        <div className="-wrapper-container">
                                                                            <button
                                                                                onClick={(e) => {
                                                                                    this.checkurl(e, "/listgameall/casino/" + listitem.partner)
                                                                                }}

                                                                                className="-btn -btn-play js-account-approve-aware"  >
                                                                                <i className="fas fa-play" />
                                                                                <span className="-text-btn">เข้าเล่น</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="-title">{listitem.description}</div>
                                                        </div>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </>;
            case 13:
                return <>
                    <div className="d-lg-none">
                        <div className="x-feed-news-header">
                            <div className="-feed-news-inner-wrapper" data-delat={200}>
                                <div className="-icon-container">
                                    <i className="fas fa-volume-up" />
                                </div>
                                <div className="-track">
                                    <div className="-track-item -duration-normal-content">
                                        Ox88 Casino Online อันดับ 1 ในไทย สล๊อตเว็บตรง ฝาก-ถอน Auto มั่นคงปลอดภัย บริการ 24 ชั่วโมง
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="-games-provider-wrapper" >
                        <div className="-menu-index-page" style={{ position: "relative" }}>
                            <div data-menu-sticky="js-sticky-widget" className="js-sticky-widget sticksy-dummy-node" style={{ overflowX: "hidden" }} >
                                <div className="x-menu-provider js-tab-menu-provider ">
                                    <nav className="nav-menu flex-column" id="navbarCategory">
                                        <ul className="nav nav-pills js-menu-container -nav-menu-container">
                                            <li className="nav-item">
                                                <button
                                                    className={"nav-link -hot-game " + (pathname === "" ? "active" : "")}
                                               

                                                    onClick={(e) => {
                                                        this.checkurl(e, "/")
                                                    }}
                                                >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-hot-game.png"
                                                            alt="คาสิโนออนไลน์ บาคาร่า สล๊อตออนไลน์ ยอดนิยม"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">HOT GAME</div>
                                                            <div className="-text-nav-menu -title-trans">ยอดนิยม</div>
                                                            <div className="-text-nav-menu -title-mobile">ยอดนิยม</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={"nav-link -casino " + (pathname === "casino" ? "active" : "")}
                                              
                                                     
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/casino")
                                                    }}
                                                >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-casino.png"
                                                            alt="คาสิโน บาคาร่า รูเล็ต ซิคโบ ไฮโล"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">CASINO</div>
                                                            <div className="-text-nav-menu -title-trans">คาสิโนสด</div>
                                                            <div className="-text-nav-menu -title-mobile">คาสิโน</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button className={"nav-link -slot " + (pathname === "slot" ? "active" : "")}
                                              
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/slot")
                                                    }}
                                                    >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-slot.png"
                                                            alt="บริการสล็อตออนไลน์ ฝาก 100 รับเครดิตฟรี 100"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">SLOT</div>
                                                            <div className="-text-nav-menu -title-trans">สล็อตเกมส์</div>
                                                            <div className="-text-nav-menu -title-mobile">สล็อต</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button className={"nav-link -sport " + (pathname === "sport" ? "active" : "")}
                                               

                                                    onClick={(e) => {
                                                        this.checkurl(e, "/sport")
                                                    }}
                                                >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-sport.png"
                                                            alt="แทงฟุตบอลพนันออนไลน์ บาสเก็ตบอล E-Sport"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">SPORT</div>
                                                            <div className="-text-nav-menu -title-trans">กีฬา</div>
                                                            <div className="-text-nav-menu -title-mobile">กีฬา</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            {/* <li className="nav-item" style={{display:"none"}}>
                                <button className={"nav-link -slot " + (pathname === "arcade" ? "active" : "")}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = '/arcade';
                                    }}>
                                    <img
                                        src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-e-sport.png"
                                        alt="บริการสล็อตออนไลน์ ฝาก 100 รับเครดิตฟรี 100"
                                        className="img-fluid -ic-menu"
                                        width={55}
                                        height={55}
                                    />
                                    <div className="-text-provider-wrapper">
                                        <div className="-text-nav-menu -title">Arcade</div>
                                        <div className="-text-nav-menu -title-trans">อาร์เคด</div>
                                        <div className="-text-nav-menu -title-mobile">อาร์เคด</div>
                                    </div>
                                </button>
                            </li> */}
                                            {/* */}
                                            <li className="nav-item" >
                                                <button
                                                    className={"nav-link -fishing-game " + (pathname === "fishing" ? "active" : "")}
                                                
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/fishing")
                                                    }}
                                                >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-fishing-game.png"
                                                            alt="ยิงปลาได้เงินจริง เครดิตฟรีแรกฝาก"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">FISHING</div>
                                                            <div className="-text-nav-menu -title-trans">ยิงปลา</div>
                                                            <div className="-text-nav-menu -title-mobile">ยิงปลา</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            {/* <li className="nav-item"  style={{display:"none"}}>
                                <button
                                    className={"nav-link -fishing-game " + (pathname === "fising" ? "active" : "")}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = '/freegame';
                                    }}
                                >
                                    <img
                                        src="/build/web/igame-index-lobby-wm/img/ajaxx-ic-nav-menu-slot.png"
                                        alt="ยิงปลาได้เงินจริง เครดิตฟรีแรกฝาก"
                                        className="img-fluid -ic-menu"
                                        width={55}
                                        height={55}
                                    />
                                    <div className="-text-provider-wrapper">
                                        <div className="-text-nav-menu -title">FREEGAME</div>
                                        <div className="-text-nav-menu -title-trans">ฟรีเกม</div>
                                        <div className="-text-nav-menu -title-mobile">ฟรีเกม</div>
                                    </div>
                                </button>
                            </li> */}
                                        </ul>
                                        <div className="-contact-wrapper  " >
                                            <div className="x-contact-us -multiple">
                                                <img
                                                    alt=""
                                                    className="img-fluid -cover-img ls-is-cached lazyloaded"
                                                    src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/contact-us-multiple-cover.png"
                                                />
                                                <div className="-btn-group-wrapper">
                                                    <a
                                                        href={this.state.linelink}
                                                        className="-link-wrapper -line-wrapper"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <img
                                                            alt=""
                                                            className="img-fluid -img ls-is-cached lazyloaded"
                                                            src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/contact-us-multiple-line.png"
                                                        />
                                                    </a>
                                                    <a
                                                        href="XXXXXXX"
                                                        className="-link-wrapper -telegram-wrapper"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <img
                                                            alt=""
                                                            className="img-fluid -img ls-is-cached lazyloaded"
                                                            src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/contact-us-multiple-telegram.png"
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                    </nav>
                                </div>
                            </div>

                        </div >
                        <div className="-games-index-page " >
                            <h2 className="-heading-sub-title">
                                Ox88Casino คาสิโนออนไลน์เต็มรูปแบบ ตื่นตาตื่นใจไปกับทุกการเดิมพัน
                            </h2>
                            <div className="x-category-provider js-game-scroll-container js-game-container">
                                <div className="-games-list-container container">
                                    <nav className="nav-menu" id="navbarProvider">
                                        <ul className="nav nav-pills">
                                            <React.Fragment>
                                                {this.state.slotlist.map((listitem, i) => (
                                                    <li key={i} id={listitem.gameid} className="nav-item -game-casino-macro-container">
                                                        <a
                                                            onClick={(e) => {
                                                                this.checkurl(e, "/listgameall/slot/" + listitem.partner)
                                                            }}
                                                            className="nav-link js-account-approve-aware"
                                                            rel="nofollow noopener"
                                                        >
                                                            <div className="x-game-list-item-macro js-game-list-toggle -big " data-status="">
                                                                <div className="-inner-wrapper">
                                                                    <picture>
                                                                        <img
                                                                            src={listitem.image}
                                                                            alt="Provider banner random"
                                                                            className="img-fluid -img-provider lazyload"
                                                                            width={334}
                                                                            height={164}
                                                                        />
                                                                    </picture>
                                                                    <div className="-overlay">
                                                                        <div className="-overlay-inner">
                                                                            <div className="-wrapper-container">
                                                                                <button

                                                                                    onClick={(e) => {
                                                                                        this.checkurl(e, "/listgameall/slot/" + listitem.partner)
                                                                                    }}
                                                                                    className="-btn -btn-play js-account-approve-aware" >
                                                                                    <i className="fas fa-play"></i>
                                                                                    <span className="-text-btn">เข้าเล่น</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="-text-nav-menu">{listitem.description}</div>
                                                        </a>
                                                    </li>
                                                ))}
                                            </React.Fragment>

                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>

                    </div>
                </>;
            case 12:
                return <>
                    <div className="d-lg-none">
                        <div className="x-feed-news-header">
                            <div className="-feed-news-inner-wrapper" data-delat={200}>
                                <div className="-icon-container">
                                    <i className="fas fa-volume-up" />
                                </div>
                                <div className="-track">
                                    <div className="-track-item -duration-normal-content">
                                        Ox88 Casino Online อันดับ 1 ในไทย สล๊อตเว็บตรง ฝาก-ถอน Auto มั่นคงปลอดภัย บริการ 24 ชั่วโมง
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="-games-provider-wrapper" >
                        <div className="-menu-index-page" style={{ position: "relative" }}>
                            <div data-menu-sticky="js-sticky-widget" className="js-sticky-widget sticksy-dummy-node" style={{ overflowX: "hidden" }} >
                                <div className="x-menu-provider js-tab-menu-provider ">
                                    <nav className="nav-menu flex-column" id="navbarCategory">
                                        <ul className="nav nav-pills js-menu-container -nav-menu-container">
                                            <li className="nav-item">
                                                <button
                                                    className={"nav-link -hot-game " + (pathname === "" ? "active" : "")}
                                              

                                                    onClick={(e) => {
                                                        this.checkurl(e, "/")
                                                    }}
                                                >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-hot-game.png"
                                                            alt="คาสิโนออนไลน์ บาคาร่า สล๊อตออนไลน์ ยอดนิยม"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">HOT GAME</div>
                                                            <div className="-text-nav-menu -title-trans">ยอดนิยม</div>
                                                            <div className="-text-nav-menu -title-mobile">ยอดนิยม</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={"nav-link -casino " + (pathname === "casino" ? "active" : "")}
                                                 

                                                    onClick={(e) => {
                                                        this.checkurl(e, "/casino")
                                                    }}
                                                >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-casino.png"
                                                            alt="คาสิโน บาคาร่า รูเล็ต ซิคโบ ไฮโล"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">CASINO</div>
                                                            <div className="-text-nav-menu -title-trans">คาสิโนสด</div>
                                                            <div className="-text-nav-menu -title-mobile">คาสิโน</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button className={"nav-link -slot " + (pathname === "slot" ? "active" : "")}
                                                
                                                      
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/slot")
                                                    }}
                                                    
                                                    >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-slot.png"
                                                            alt="บริการสล็อตออนไลน์ ฝาก 100 รับเครดิตฟรี 100"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">SLOT</div>
                                                            <div className="-text-nav-menu -title-trans">สล็อตเกมส์</div>
                                                            <div className="-text-nav-menu -title-mobile">สล็อต</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button className={"nav-link -sport " + (pathname === "sport" ? "active" : "")}
                                                
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/sport")
                                                    }}
                                                >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-sport.png"
                                                            alt="แทงฟุตบอลพนันออนไลน์ บาสเก็ตบอล E-Sport"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">SPORT</div>
                                                            <div className="-text-nav-menu -title-trans">กีฬา</div>
                                                            <div className="-text-nav-menu -title-mobile">กีฬา</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            {/* <li className="nav-item" style={{display:"none"}}>
                                <button className={"nav-link -slot " + (pathname === "arcade" ? "active" : "")}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = '/arcade';
                                    }}>
                                    <img
                                        src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-e-sport.png"
                                        alt="บริการสล็อตออนไลน์ ฝาก 100 รับเครดิตฟรี 100"
                                        className="img-fluid -ic-menu"
                                        width={55}
                                        height={55}
                                    />
                                    <div className="-text-provider-wrapper">
                                        <div className="-text-nav-menu -title">Arcade</div>
                                        <div className="-text-nav-menu -title-trans">อาร์เคด</div>
                                        <div className="-text-nav-menu -title-mobile">อาร์เคด</div>
                                    </div>
                                </button>
                            </li> */}
                                            {/* */}
                                            <li className="nav-item" >
                                                <button
                                                    className={"nav-link -fishing-game " + (pathname === "fishing" ? "active" : "")}
                                                  

                                                    onClick={(e) => {
                                                        this.checkurl(e, "/fishing")
                                                    }}
                                                >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-fishing-game.png"
                                                            alt="ยิงปลาได้เงินจริง เครดิตฟรีแรกฝาก"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">FISHING</div>
                                                            <div className="-text-nav-menu -title-trans">ยิงปลา</div>
                                                            <div className="-text-nav-menu -title-mobile">ยิงปลา</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            {/* <li className="nav-item"  style={{display:"none"}}>
                                <button
                                    className={"nav-link -fishing-game " + (pathname === "fising" ? "active" : "")}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = '/freegame';
                                    }}
                                >
                                    <img
                                        src="/build/web/igame-index-lobby-wm/img/ajaxx-ic-nav-menu-slot.png"
                                        alt="ยิงปลาได้เงินจริง เครดิตฟรีแรกฝาก"
                                        className="img-fluid -ic-menu"
                                        width={55}
                                        height={55}
                                    />
                                    <div className="-text-provider-wrapper">
                                        <div className="-text-nav-menu -title">FREEGAME</div>
                                        <div className="-text-nav-menu -title-trans">ฟรีเกม</div>
                                        <div className="-text-nav-menu -title-mobile">ฟรีเกม</div>
                                    </div>
                                </button>
                            </li> */}
                                        </ul>
                                        <div className="-contact-wrapper  " >
                                            <div className="x-contact-us -multiple">
                                                <img
                                                    alt=""
                                                    className="img-fluid -cover-img ls-is-cached lazyloaded"
                                                    src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/contact-us-multiple-cover.png"
                                                />
                                                <div className="-btn-group-wrapper">
                                                    <a
                                                        href={this.state.linelink}
                                                        className="-link-wrapper -line-wrapper"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <img
                                                            alt=""
                                                            className="img-fluid -img ls-is-cached lazyloaded"
                                                            src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/contact-us-multiple-line.png"
                                                        />
                                                    </a>
                                                    <a
                                                        href="XXXXXXX"
                                                        className="-link-wrapper -telegram-wrapper"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <img
                                                            alt=""
                                                            className="img-fluid -img ls-is-cached lazyloaded"
                                                            src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/contact-us-multiple-telegram.png"
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                    </nav>
                                </div>
                            </div>

                        </div >
                        <div className="-games-index-page " >
                            <h2 className="-heading-sub-title">
                                Ox88 Casino คาสิโนออนไลน์เต็มรูปแบบ ตื่นตาตื่นใจไปกับทุกการเดิมพัน
                            </h2>
                            <div className="x-category-provider js-game-scroll-container js-game-container">
                                <div className="-games-list-container container">
                                    <nav className="nav-menu" id="navbarProvider">
                                        <ul className="nav nav-pills">
                                            {this.state.fishinglist.map((listitem, i) => (
                                                <li key={listitem.i} id={listitem.gameid} className="nav-item -game-casino-macro-container">
                                                    <a
                                                        onClick={(e) => {
                                                            this.checkurl(e, "/listgameall/fishing/" + listitem.partner)
                                                        }}
                                                        className="nav-link js-account-approve-aware"
                                                        rel="nofollow noopener"
                                                    >
                                                        <div className="x-game-list-item-macro js-game-list-toggle -big " data-status>
                                                            <div className="-inner-wrapper">
                                                                <picture>
                                                                    <img src={listitem.image} className="-cover-img lazyload img-fluid" alt="dream-gaming" width={364} height={231} />
                                                                </picture>
                                                                <div className="-overlay">
                                                                    <div className="-overlay-inner">
                                                                        <div className="-wrapper-container">
                                                                            <button

                                                                                onClick={(e) => {
                                                                                    this.checkurl(e, "/listgameall/fishing/" + listitem.partner)
                                                                                }} className="-btn -btn-play js-account-approve-aware"  >
                                                                                <i className="fas fa-play" />
                                                                                <span className="-text-btn">เข้าเล่น</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="-title">{listitem.description}</div>
                                                        </div>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>

                    </div>
                </>;
            case 14:
                return <>

                    <div className="d-lg-none">
                        <div className="x-feed-news-header">
                            <div className="-feed-news-inner-wrapper" data-delat={200}>
                                <div className="-icon-container">
                                    <i className="fas fa-volume-up" />
                                </div>
                                <div className="-track">
                                    <div className="-track-item -duration-normal-content">
                                        Ox88 Casino Online อันดับ 1 ในไทย สล๊อตเว็บตรง ฝาก-ถอน Auto มั่นคงปลอดภัย บริการ 24 ชั่วโมง
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="-games-provider-wrapper" >
                        <div className="-menu-index-page" style={{ position: "relative" }}>
                            <div data-menu-sticky="js-sticky-widget" className="js-sticky-widget sticksy-dummy-node" style={{ overflowX: "hidden" }} >
                                <div className="x-menu-provider js-tab-menu-provider ">
                                    <nav className="nav-menu flex-column" id="navbarCategory">
                                        <ul className="nav nav-pills js-menu-container -nav-menu-container">
                                            <li className="nav-item">
                                                <button
                                                    className={"nav-link -hot-game " + (pathname === "" ? "active" : "")}
                                                

                                                    onClick={(e) => {
                                                        this.checkurl(e, "/")
                                                    }}
                                                >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-hot-game.png"
                                                            alt="คาสิโนออนไลน์ บาคาร่า สล๊อตออนไลน์ ยอดนิยม"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">HOT GAME</div>
                                                            <div className="-text-nav-menu -title-trans">ยอดนิยม</div>
                                                            <div className="-text-nav-menu -title-mobile">ยอดนิยม</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={"nav-link -casino " + (pathname === "casino" ? "active" : "")}
                                                 
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/casino")
                                                    }}
                                                >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-casino.png"
                                                            alt="คาสิโน บาคาร่า รูเล็ต ซิคโบ ไฮโล"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">CASINO</div>
                                                            <div className="-text-nav-menu -title-trans">คาสิโนสด</div>
                                                            <div className="-text-nav-menu -title-mobile">คาสิโน</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button className={"nav-link -slot " + (pathname === "slot" ? "active" : "")}
                                              
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/slot")
                                                    }}
                                                    >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-slot.png"
                                                            alt="บริการสล็อตออนไลน์ ฝาก 100 รับเครดิตฟรี 100"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">SLOT</div>
                                                            <div className="-text-nav-menu -title-trans">สล็อตเกมส์</div>
                                                            <div className="-text-nav-menu -title-mobile">สล็อต</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button className={"nav-link -sport " + (pathname === "sport" ? "active" : "")}
                                              

                                                    onClick={(e) => {
                                                        this.checkurl(e, "/sport")
                                                    }}
                                                >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-sport.png"
                                                            alt="แทงฟุตบอลพนันออนไลน์ บาสเก็ตบอล E-Sport"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">SPORT</div>
                                                            <div className="-text-nav-menu -title-trans">กีฬา</div>
                                                            <div className="-text-nav-menu -title-mobile">กีฬา</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            {/* <li className="nav-item" style={{display:"none"}}>
                                <button className={"nav-link -slot " + (pathname === "arcade" ? "active" : "")}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = '/arcade';
                                    }}>
                                    <img
                                        src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-e-sport.png"
                                        alt="บริการสล็อตออนไลน์ ฝาก 100 รับเครดิตฟรี 100"
                                        className="img-fluid -ic-menu"
                                        width={55}
                                        height={55}
                                    />
                                    <div className="-text-provider-wrapper">
                                        <div className="-text-nav-menu -title">Arcade</div>
                                        <div className="-text-nav-menu -title-trans">อาร์เคด</div>
                                        <div className="-text-nav-menu -title-mobile">อาร์เคด</div>
                                    </div>
                                </button>
                            </li> */}
                                            {/* */}
                                            <li className="nav-item" >
                                                <button
                                                    className={"nav-link -fishing-game " + (pathname === "fishing" ? "active" : "")}
                                              
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/fishing")
                                                    }}
                                                >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-fishing-game.png"
                                                            alt="ยิงปลาได้เงินจริง เครดิตฟรีแรกฝาก"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">FISHING</div>
                                                            <div className="-text-nav-menu -title-trans">ยิงปลา</div>
                                                            <div className="-text-nav-menu -title-mobile">ยิงปลา</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            {/* <li className="nav-item"  style={{display:"none"}}>
                                <button
                                    className={"nav-link -fishing-game " + (pathname === "fising" ? "active" : "")}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = '/freegame';
                                    }}
                                >
                                    <img
                                        src="/build/web/igame-index-lobby-wm/img/ajaxx-ic-nav-menu-slot.png"
                                        alt="ยิงปลาได้เงินจริง เครดิตฟรีแรกฝาก"
                                        className="img-fluid -ic-menu"
                                        width={55}
                                        height={55}
                                    />
                                    <div className="-text-provider-wrapper">
                                        <div className="-text-nav-menu -title">FREEGAME</div>
                                        <div className="-text-nav-menu -title-trans">ฟรีเกม</div>
                                        <div className="-text-nav-menu -title-mobile">ฟรีเกม</div>
                                    </div>
                                </button>
                            </li> */}
                                        </ul>
                                        <div className="-contact-wrapper  " >
                                            <div className="x-contact-us -multiple">
                                                <img
                                                    alt=""
                                                    className="img-fluid -cover-img ls-is-cached lazyloaded"
                                                    src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/contact-us-multiple-cover.png"
                                                />
                                                <div className="-btn-group-wrapper">
                                                    <a
                                                        href={this.state.linelink}
                                                        className="-link-wrapper -line-wrapper"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <img
                                                            alt=""
                                                            className="img-fluid -img ls-is-cached lazyloaded"
                                                            src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/contact-us-multiple-line.png"
                                                        />
                                                    </a>
                                                    <a
                                                        href="XXXXXXX"
                                                        className="-link-wrapper -telegram-wrapper"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <img
                                                            alt=""
                                                            className="img-fluid -img ls-is-cached lazyloaded"
                                                            src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/contact-us-multiple-telegram.png"
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                    </nav>
                                </div>
                            </div>

                        </div >
                        <div className="-games-index-page" >
                            <h2 className="-heading-sub-title">
                                Ox88Casino คาสิโนออนไลน์เต็มรูปแบบ ตื่นตาตื่นใจไปกับทุกการเดิมพัน
                            </h2>
                            <div className="x-category-provider js-game-scroll-container js-game-container">
                                <div className="-games-list-container container">
                                    <nav className="nav-menu" id="navbarProvider">
                                        <ul className="nav nav-pills">
                                            {this.state.sportlist.map((listitem, i) => (
                                                <li key={i} id={listitem.gameid} className="nav-item -game-casino-macro-container">
                                                    <a
                                                        onClick={(e) => {
                                                            this.checkurl(e, "/listgameall/sport/" + listitem.partner)
                                                        }}
                                                        className="nav-link js-account-approve-aware"
                                                        rel="nofollow noopener"
                                                    >
                                                        <div className="x-game-list-item-macro js-game-list-toggle -big " data-status>
                                                            <div className="-inner-wrapper">
                                                                <picture>
                                                                    <img src={listitem.image} className="-cover-img lazyload img-fluid" alt="dream-gaming" width={364} height={231} />
                                                                </picture>
                                                                <div className="-overlay">
                                                                    <div className="-overlay-inner">
                                                                        <div className="-wrapper-container">
                                                                            <button
                                                                                onClick={(e) => {
                                                                                    this.checkurl(e, "/listgameall/sport/" + listitem.partner)
                                                                                }}
                                                                                className="-btn -btn-play js-account-approve-aware"  >
                                                                                <i className="fas fa-play" />
                                                                                <span className="-text-btn">เข้าเล่น</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="-title">{listitem.description}</div>
                                                        </div>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </>;
            case 15:
                return <Listgameall />;
            default:

                return <>
                    <div className="d-lg-none">
                        <div className="x-feed-news-header">
                            <div className="-feed-news-inner-wrapper" data-delat={200}>
                                <div className="-icon-container">
                                    <i className="fas fa-volume-up" />
                                </div>
                                <div className="-track">
                                    <div className="-track-item -duration-normal-content">
                                        Ox88 Casino Online อันดับ 1 ในไทย สล๊อตเว็บตรง ฝาก-ถอน Auto มั่นคงปลอดภัย บริการ 24 ชั่วโมง
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="-games-provider-wrapper" >
                        <div className="-menu-index-page" style={{ position: "relative" }}>
                            <div data-menu-sticky="js-sticky-widget" className="js-sticky-widget sticksy-dummy-node" style={{ overflowX: "hidden" }} >
                                <div className="x-menu-provider js-tab-menu-provider ">
                                    <nav className="nav-menu flex-column" id="navbarCategory">
                                        <ul className="nav nav-pills js-menu-container -nav-menu-container">
                                            <li className="nav-item">
                                                <button
                                                    className={"nav-link -hot-game " + (pathname === "" ? "active" : "")}
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/")
                                                    }}
                                                >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-hot-game.png"
                                                            alt="คาสิโนออนไลน์ บาคาร่า สล๊อตออนไลน์ ยอดนิยม"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">HOT GAME</div>
                                                            <div className="-text-nav-menu -title-trans">ยอดนิยม</div>
                                                            <div className="-text-nav-menu -title-mobile">ยอดนิยม</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={"nav-link -casino " + (pathname === "casino" ? "active" : "")}
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/casino")
                                                    }}
                                                >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-casino.png"
                                                            alt="คาสิโน บาคาร่า รูเล็ต ซิคโบ ไฮโล"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">CASINO</div>
                                                            <div className="-text-nav-menu -title-trans">คาสิโนสด</div>
                                                            <div className="-text-nav-menu -title-mobile">คาสิโน</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button className={"nav-link -slot " + (pathname === "slot" ? "active" : "")}
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/slot")
                                                    }}>
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-slot.png"
                                                            alt="บริการสล็อตออนไลน์ ฝาก 100 รับเครดิตฟรี 100"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">SLOT</div>
                                                            <div className="-text-nav-menu -title-trans">สล็อตเกมส์</div>
                                                            <div className="-text-nav-menu -title-mobile">สล็อต</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button className={"nav-link -sport " + (pathname === "sport" ? "active" : "")}
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/sport")
                                                    }}
                                                >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-sport.png"
                                                            alt="แทงฟุตบอลพนันออนไลน์ บาสเก็ตบอล E-Sport"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">SPORT</div>
                                                            <div className="-text-nav-menu -title-trans">กีฬา</div>
                                                            <div className="-text-nav-menu -title-mobile">กีฬา</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                            {/* <li className="nav-item" style={{display:"none"}}>
                                <button className={"nav-link -slot " + (pathname === "arcade" ? "active" : "")}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = '/arcade';
                                    }}>
                                    <img
                                        src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-e-sport.png"
                                        alt="บริการสล็อตออนไลน์ ฝาก 100 รับเครดิตฟรี 100"
                                        className="img-fluid -ic-menu"
                                        width={55}
                                        height={55}
                                    />
                                    <div className="-text-provider-wrapper">
                                        <div className="-text-nav-menu -title">Arcade</div>
                                        <div className="-text-nav-menu -title-trans">อาร์เคด</div>
                                        <div className="-text-nav-menu -title-mobile">อาร์เคด</div>
                                    </div>
                                </button>
                            </li> */}
                                            {/* */}
                                            <li className="nav-item" >
                                                <button
                                                    className={"nav-link -fishing-game " + (pathname === "fishing" ? "active" : "")}

                                                    onClick={(e) => {
                                                        this.checkurl(e, "/fishing")
                                                    }}
                                                >
                                                    <div className="-nav-link-wrapper">
                                                        <img
                                                            src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-fishing-game.png"
                                                            alt="ยิงปลาได้เงินจริง เครดิตฟรีแรกฝาก"
                                                            className="img-fluid -ic-menu"
                                                            width={55}
                                                            height={55}
                                                        />
                                                        <div className="-text-provider-wrapper">
                                                            <div className="-text-nav-menu -title">FISHING</div>
                                                            <div className="-text-nav-menu -title-trans">ยิงปลา</div>
                                                            <div className="-text-nav-menu -title-mobile">ยิงปลา</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </li>
                                           
                                        </ul>
                                        <div className="-contact-wrapper  " >
                                            <div className="x-contact-us -multiple">
                                                <img
                                                    alt=""
                                                    className="img-fluid -cover-img ls-is-cached lazyloaded"
                                                    src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/contact-us-multiple-cover.png"
                                                />
                                                <div className="-btn-group-wrapper">
                                                    <a
                                                        href={this.state.linelink}
                                                        className="-link-wrapper -line-wrapper"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <img
                                                            alt=""
                                                            className="img-fluid -img ls-is-cached lazyloaded"
                                                            src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/contact-us-multiple-line.png"
                                                        />
                                                    </a>
                                                    <a
                                                        href="XXXXXXX"
                                                        className="-link-wrapper -telegram-wrapper"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <img
                                                            alt=""
                                                            className="img-fluid -img ls-is-cached lazyloaded"
                                                            src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/contact-us-multiple-telegram.png"
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                    </nav>
                                </div>
                            </div>

                        </div >
                        <div className="-games-index-page">
                            <h2 className="-heading-sub-title">Ox88  Casino คาสิโนออนไลน์เต็มรูปแบบ
                                ตื่นตาตื่นใจไปกับทุกการเดิมพัน</h2>
                            <div className="x-category-provider js-game-scroll-container js-game-container">
                                <div className="-games-list-container container">
                                    <div className="x-hot-games-container">
                                        {(this.state.recentmenu) ?
                                            <div>
                                                <div className="-inner-heading-wrapper">
                                                    <img src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/ic-nav-menu-popular-2.png" className="-icon" alt="เกมคาสิโนยอดนิยม เกมสล็อตยอดนิยม" width={55} height={55} />
                                                    <h2 className="-title">เล่นล่าสุด</h2>
                                                </div>
                                                <nav className="nav-menu" id="navbarProvider">
                                                    <ul className="nav nav-pills">
                                                        {this.state.recentlist.map((listitem, i) => (
                                                            <li
                                                                key={i} id={listitem.gameid}
                                                                className="nav-item -game-slot-macro-container -square">
                                                                <a
                                                                    href={() => false}
                                                                    onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}
                                                                    className="nav-link js-account-approve-aware"
                                                                    rel="nofollow noopener"
                                                                >
                                                                    <div className="x-game-list-item-macro js-game-list-toggle -normal " data-status>
                                                                        <div className="-inner-wrapper">
                                                                            <img
                                                                                src={listitem.image}
                                                                                width={250} height={250}
                                                                                className="-cover-img lazyload img-fluid" alt="Crown & Anchor | evo-play เว็บคาสิโนออนไลน์ สล็อตออนไลน์ รับเครดิตฟรี" />
                                                                            <div className="-overlay">
                                                                                <div className="-overlay-inner">
                                                                                    <div className="-wrapper-container">
                                                                                        <button

                                                                                            onClick={(e) => {
                                                                                                this.checkurl(e, "/listgameall/slot/" + listitem.partner)
                                                                                            }}
                                                                                            className="-btn -btn-play js-account-approve-aware" >
                                                                                            <i className="fas fa-play"></i>
                                                                                            <span className="-text-btn">เข้าเล่น</span>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="-title">{listitem.description}</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </nav>
                                            </div>
                                            : ""
                                        }
                                        <div className="-inner-heading-wrapper">
                                            <img src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-popular-casino.png" className="-icon" alt="เกมคาสิโนยอดนิยม เกมสล็อตยอดนิยม" width={55} height={55} />
                                            <h2 className="-title">คาสิโนออนไลน์ ยอดนิยม</h2>
                                        </div>
                                        <nav className="nav-menu" id="navbarProvider">
                                            <ul className="nav nav-pills">
                                                {this.state.categorylist.map((listitem, i) => (
                                                    <li key={i} id={listitem.gameid} className="nav-item -game-casino-macro-container">
                                                        <a
                                                            onClick={(e) => {
                                                                this.checkurl(e, "/listgameall/casino/" + listitem.partner)
                                                            }}
                                                            className="nav-link js-account-approve-aware"
                                                            rel="nofollow noopener">
                                                            <div className="x-game-list-item-macro js-game-list-toggle -big " data-status>
                                                                <div className="-inner-wrapper">
                                                                    <div className="x-game-badge-component -popular -big" data-delay={400}>
                                                                        <span>Popular</span>
                                                                    </div>
                                                                    <picture>
                                                                        <img
                                                                            src={this.addDefaultSrc(null, listitem.image)}
                                                                            className="-cover-img lazyload img-fluid" alt="dream-gaming" width={364} height={231} />
                                                                    </picture>
                                                                    <div className="-overlay">
                                                                        <div className="-overlay-inner">
                                                                            <div className="-wrapper-container">
                                                                                <button
                                                                                    onClick={(e) => {
                                                                                        this.checkurl(e, "/listgameall/casino/" + listitem.partner)
                                                                                    }}


                                                                                    className="-btn -btn-play js-account-approve-aware"  >
                                                                                    <i className="fas fa-play" />
                                                                                    <span className="-text-btn">เข้าเล่น</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="-title">{listitem.description}</div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </nav>
                                        <div className="-hot-slot-container">
                                            <div className="-inner-heading-wrapper">
                                                <img src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-popular-slot.png" className="-icon" alt="เกมคาสิโนยอดนิยม เกมสล็อตยอดนิยม" width={55} height={55} />
                                                <h2 className="-title">สล็อตออนไลน์ ยอดนิยม</h2>
                                            </div>
                                            <nav className="nav-menu" id="navbarProvider">
                                                <ul className="nav nav-pills">
                                                    {this.state.slotlist.map((listitem, i) => (
                                                        <li key={i} id={listitem.gameid} className="nav-item -game-slot-macro-container -square">
                                                            <a
                                                                onClick={(e) => {
                                                                    this.checkurl(e, "/listgameall/slot/" + listitem.partner)
                                                                }}
                                                                className="nav-link js-account-approve-aware"
                                                                rel="nofollow noopener"
                                                            >
                                                                <div className="x-game-list-item-macro js-game-list-toggle -normal " data-status>
                                                                    <div className="-inner-wrapper">
                                                                        <img
                                                                            src={this.addDefaultSrc(null, listitem.image)}
                                                                            width={250} height={250} className="-cover-img lazyload img-fluid" alt="Crown & Anchor | evo-play เว็บคาสิโนออนไลน์ สล็อตออนไลน์ รับเครดิตฟรี" />
                                                                        <div className="-overlay">
                                                                            <div className="-overlay-inner">
                                                                                <div className="-wrapper-container">
                                                                                    <button

                                                                                        onClick={(e) => {
                                                                                            this.checkurl(e, "/listgameall/slot/" + listitem.partner)
                                                                                        }} className="-btn -btn-play js-account-approve-aware" >
                                                                                        <i className="fas fa-play"></i>
                                                                                        <span className="-text-btn">เข้าเล่น</span>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="-title">{listitem.description}</div>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>;
        }
    }
    render() {
        const pathname = this.state.pathname
        console.log("render", pathname);
        return (
            <>
                <div className="x-main-container ">
                    <div className="x-main-side-bar">
                        <div className="x-menu-provider js-tab-menu-provider -desktop-view">
                            <nav className="nav-menu" id="navbarCategory">
                                <ul className="nav nav-pills js-menu-container -nav-menu-container">
                                    <li className="nav-item">
                                        <button
                                            className={"nav-link -hot-game " + (pathname === "" ? "active" : "")}
                                            onClick={(e) => {
                                                this.checkurl(e, "/")
                                            }}
                                        >
                                            <div className="-nav-link-wrapper" >
                                                <img
                                                    src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-hot-game.png"
                                                    alt="คาสิโนออนไลน์ บาคาร่า สล๊อตออนไลน์ ยอดนิยม"
                                                    className="img-fluid -ic-menu"
                                                    width={55}
                                                    height={55}
                                                />
                                                <div className="-text-provider-wrapper">
                                                    <div className="-text-nav-menu -title">HOT GAME</div>
                                                    <div className="-text-nav-menu -title-trans">ยอดนิยม</div>
                                                    <div className="-text-nav-menu -title-mobile">ยอดนิยม</div>
                                                </div>
                                            </div>
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button
                                            className={"nav-link -casino " + (pathname === "casino" ? "active" : "")}
                                            onClick={(e) => {
                                                this.checkurl(e, "/casino")
                                            }}
                                        >
                                            <div className="-nav-link-wrapper" >
                                                <img
                                                    src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-casino.png"
                                                    alt="คาสิโน บาคาร่า รูเล็ต ซิคโบ ไฮโล"
                                                    className="img-fluid -ic-menu"
                                                    width={55}
                                                    height={55}
                                                />
                                                <div className="-text-provider-wrapper">
                                                    <div className="-text-nav-menu -title">CASINO</div>
                                                    <div className="-text-nav-menu -title-trans">คาสิโนสด</div>
                                                    <div className="-text-nav-menu -title-mobile">คาสิโน</div>
                                                </div>
                                            </div>
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button className={"nav-link -slot " + (pathname === "slot" ? "active" : "")}

                                            onClick={(e) => {
                                                this.checkurl(e, "/slot")
                                            }}

                                        >
                                            <div className="-nav-link-wrapper" >
                                                <img
                                                    src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-slot.png"
                                                    alt="บริการสล็อตออนไลน์ ฝาก 100 รับเครดิตฟรี 100"
                                                    className="img-fluid -ic-menu"
                                                    width={55}
                                                    height={55}
                                                />
                                                <div className="-text-provider-wrapper">
                                                    <div className="-text-nav-menu -title">SLOT</div>
                                                    <div className="-text-nav-menu -title-trans">สล็อตเกมส์</div>
                                                    <div className="-text-nav-menu -title-mobile">สล็อต</div>
                                                </div>
                                            </div>
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button
                                            className={"nav-link -fishing-game " + (pathname === "fishing" ? "active" : "")}

                                            onClick={(e) => {
                                                this.checkurl(e, "/fishing")
                                            }}
                                        >
                                            <div className="-nav-link-wrapper" >
                                                <img
                                                    src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-fishing-game.png"
                                                    alt="ยิงปลาได้เงินจริง เครดิตฟรีแรกฝาก"
                                                    className="img-fluid -ic-menu"
                                                    width={55}
                                                    height={55}
                                                />
                                                <div className="-text-provider-wrapper">
                                                    <div className="-text-nav-menu -title">FISHING</div>
                                                    <div className="-text-nav-menu -title-trans">ยิงปลา</div>
                                                    <div className="-text-nav-menu -title-mobile">ยิงปลา</div>
                                                </div>
                                            </div>
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button className={"nav-link -sport " + (pathname === "sport" ? "active" : "")}

                                            onClick={(e) => {
                                                this.checkurl(e, "/sport")
                                            }}
                                        >
                                            <div className="-nav-link-wrapper" >
                                                <img
                                                    src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-sport.png"
                                                    alt="แทงฟุตบอลพนันออนไลน์ บาสเก็ตบอล E-Sport"
                                                    className="img-fluid -ic-menu"
                                                    width={55}
                                                    height={55}
                                                />
                                                <div className="-text-provider-wrapper">
                                                    <div className="-text-nav-menu -title">SPORT</div>
                                                    <div className="-text-nav-menu -title-trans">กีฬา</div>
                                                    <div className="-text-nav-menu -title-mobile">กีฬา</div>
                                                </div>
                                            </div>
                                        </button>
                                    </li>
                                </ul>
                                <div className="-contact-wrapper ">
                                    <div className="x-contact-us -multiple">
                                        <img

                                            src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/contact-us-multiple-cover.png"
                                            alt=""
                                            className="img-fluid -cover-img ls-is-cached lazyloaded"
                                        />
                                        <div className="-btn-group-wrapper">
                                            <a
                                                href={this.state.linelink}
                                                className="-link-wrapper -line-wrapper"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img

                                                    src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/contact-us-multiple-line.png"
                                                    alt=""
                                                    className="img-fluid -img ls-is-cached lazyloaded"
                                                />
                                            </a>
                                            <a
                                                href="XXXXXXX"
                                                className="-link-wrapper -telegram-wrapper"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img

                                                    src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/contact-us-multiple-telegram.png"
                                                    alt=""
                                                    className="img-fluid -img ls-is-cached lazyloaded"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div className="x-main-content">
                        <section className="x-index-top-container" >
                            <div className="-index-inner-wrapper" style={{ backgroundColor: "black" }}>
                                <div className="x-special-menu-entry-wrapper js-special-menu-entry-wrapper">
                                    <div className="container">
                                        <div className="x-special-menu-entry -main-container">
                                            <ul className="nav -nav">
                                                <li className="nav-item">
                                                    {this.state.loginstate ? <button
                                                        className="nav-link -menu-bind-social animated fadeInUp"
                                                        data-toggle="modal"
                                                        data-target="#socialsharemodal1"
                                                        data-dismiss="modal"
                                                        data-delay={100}
                                                    >
                                                        <img
                                                            alt="Website name Image"
                                                            className="-bg"
                                                            src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-item-bg.png?v=1"
                                                        />
                                                        <img
                                                            alt="Website name Image"
                                                            className="-text-img img-fluid"
                                                            src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-social-share.png?v=1"
                                                        />
                                                    </button> : <button
                                                        className="nav-link -menu-bind-social animated fadeInUp"
                                                        data-toggle="modal"
                                                        data-target="#loginModal"
                                                        data-dismiss="modal"

                                                        data-delay={100}
                                                    >
                                                        <img
                                                            alt="Website name Image"
                                                            className="-bg"
                                                            src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-item-bg.png?v=1"
                                                        />
                                                        <img
                                                            alt="Website name Image"
                                                            className="-text-img img-fluid"
                                                            src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-social-share.png?v=1"
                                                        />
                                                    </button>}


                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        href="/lucky-fortune"
                                                        className="nav-link -menu-wheel animated fadeInUp"

                                                        data-delay={150}
                                                    >
                                                        <img
                                                            alt="Website name Image"
                                                            className="-bg"
                                                            src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-item-bg.png?v=1"
                                                        />
                                                        <img
                                                            alt="Website name Image"
                                                            className="-text-img img-fluid"
                                                            src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-wheel.png?v=1"
                                                        />
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link -menu-bind-social animated fadeInUp"
                                                        href="https://t.me/+VKuZm4XsrNxhYzc1"

                                                        data-delay={100}
                                                    >
                                                        <img
                                                            alt="Website name Image"
                                                            className="-bg"
                                                            src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-item-bg.png?v=1"
                                                        />
                                                        <img
                                                            alt="Website name Image"
                                                            className="-text-img img-fluid"
                                                            src="https://cdn.javisx.com/image/d44c6d4b-db93-4f00-89ac-09408814940b.png"
                                                        />
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        href="/Affiliate"
                                                        className="nav-link -menu-invitation animated fadeInUp"

                                                        data-delay={150}
                                                    >
                                                        <img
                                                            alt="Website name Image"
                                                            className="-bg"
                                                            src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-item-bg.png?v=1"
                                                        />
                                                        <img
                                                            alt="Website name Image"
                                                            className="-text-img img-fluid"
                                                            src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-invitation.png?v=1"
                                                        />
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        href="https://lin.ee/FgUT4Dr"
                                                        className="nav-link -menu-birth-date animated fadeInUp"
                                                        target="_blank"
                                                        rel="nofollow noopener"

                                                        data-delay={200}
                                                    >
                                                        <img
                                                            alt="Website name Image"
                                                            className="-bg"
                                                            src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-item-bg.png?v=1"
                                                        />
                                                        <img
                                                            alt="Website name Image"
                                                            className="-text-img img-fluid"
                                                            src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-happy-birth-day.png?v=1"
                                                        />
                                                    </a>
                                                </li>
                                                {/* <li className="nav-item">
                                <a
                                     href="/promotions"
                                    className="nav-link -menu-ranking animated fadeInUp"
                                   
                                    data-delay={250}
                                >
                                    <img
                                        alt="Website name Image"
                                        className="-bg"
                                        src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-item-bg.png?v=1"
                                    />
                                    <img
                                        alt="Website name Image"
                                        className="-text-img img-fluid"
                                        src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-ranking.png?v=1"
                                    />
                                </a>
                            </li> */}
                                                <li className="nav-item">
                                                    <a
                                                        href="https://www.nung.tv/"
                                                        className="nav-link -menu-movie animated fadeInUp"
                                                        target="_blank"
                                                        rel="nofollow noopener"

                                                        data-delay={300}
                                                    >
                                                        <img
                                                            alt="Website name Image"
                                                            className="-bg"
                                                            src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-item-bg.png?v=1"
                                                        />
                                                        <img
                                                            alt="Website name Image"
                                                            className="-text-img img-fluid"
                                                            src="/build/web/igame-index-lobby-ae-sexy/img/special-menu-entry-movie.png?v=1"
                                                        />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="-heading-title">Ox88  Casino คาสิโนออนไลน์ บาคาร่า ไฮโล เสือมังกร เว็บตรง Ox88
                                </h1>
                                <Listmenu />
                                {this.renderTabContent()}
                            </div>
                            <Lowmenu />
                        </section>
                        <Footer />
                    </div>
                </div>
            </>
        );
    }
}

export default Main;
