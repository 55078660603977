import React, { Component } from 'react'; //different
import './App.css';
import Footer from './footer';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2'
import instance from "./axios-instance";
import { NumericFormat } from 'react-number-format';
import Systems from "./SystemFunction";
import moment from 'moment';
import Sidebar from './sidebar';
import md5 from 'md5';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            rewardlist: [],
            activePage: 1,
            pagecount: 1,
            products_page_count: 1,
            isActive: false,
            activeTab: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            tblprofile: [],
            userid: null,
            linkaff: "",
            point: 0,
            useradvice: [],
            balance: 0,
            loginstate: null,
            firstname: null,
            lastname: null,
            alert: null,
            modalOpen: false,
            handleClose: false,
            proderedeemlist: [],
            token: window.localStorage.getItem("token"),
            type: "",
            username: "",
            tabselect: 1,
            amount: "",
            remark: "",
            mainacc: "",
            rankpic: "1.jpeg",
            rankname: null,
            rankob: null,
            rankpoint: 0,
            name: "",
            password: "",
            left: false,
        }
    }
    logout = (e) => {

        // window.location.href = '/logout';

    }
    waitnotify = async () => {
        setTimeout(() => { console.log('World!') }, 5000)
    }
    async opencliaminvite(evt, cityName) {
        console.log(cityName);

        var token = localStorage.getItem('auth_token');
        if (token) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            await instance.post("/api/v1/cliaminvite", {
                userid: Username,
                System: Systems,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                if (res.data.status === 200) {
                    var decoded = jwt_decode(token);
                    var Username = decoded.message.playerid;
                    this.rechecklogin(Username, token);
                    await Swal.fire({
                        title: 'Success!',
                        text: 'ทำรายการรับเงินเรียบร้อย',
                        icon: 'success',
                        confirmButtonText: 'ตกลง'
                    })

                }
                else if (res.data.status === 201) {
                    await Swal.fire({
                        title: 'error!',
                        text: 'ไม่สามารถทำรายการได้ ไม่มียอดเงิน',
                        icon: 'error',
                        confirmButtonText: 'ตกลง'
                    })
                    return false;
                }
                else if (res.data.status === 202) {
                    await Swal.fire({
                        title: 'error!',
                        text: 'ไม่สามารถทำรายการได้',
                        icon: 'error',
                        confirmButtonText: 'ตกลง'
                    })
                    return false;
                }

            }).catch(async (err) => {
                // console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        else {
            await Swal.fire({
                title: 'error!',
                text: 'กรุณาลีอคอิน',
                icon: 'error',
                confirmButtonText: 'ตกลง'
            })
            return false;
        }
    }
    checktab(e, index) {
        //console.log(index == 2 &&  this.state.qrcode != null);

        this.setState({
            tabselect: index,
        });


    }
    notify = async (mode, msg) => {
        console.log(mode, msg);
        if (mode == "success") {
            await toast.success(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "warning") {
            await toast.warning(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "error") {
            await toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "info") {
            await toast.info(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        await this.waitnotify();
    }
    copyCode = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                this.notify("warning", 'CopyCode!!!');
            })
            .catch((err) => {
                // console.log(err.message);
            });

    };
    async componentDidMount() {
        await instance.post("/api/v1/mainsetting", {
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                const datas = res.data.message;
                this.setState({
                    linelink: datas[1].value,
                });
            }
            else {
                // this.CheckThisGoalError();
            }
        });
        var userAgent = navigator.userAgent.toLowerCase()
        try {
            var token = localStorage.getItem('auth_token');
            //  //////console.log(token);
            if (token != null) {
                var decoded = jwt_decode(token);
                var Username = decoded.message.playerid;
                await this.rechecklogin(Username, token);
                this.setState({
                    userid: decoded.message.playerid,
                    username: decoded.message.playerid,
                    firstname: decoded.message.firstname,
                    lastname: decoded.message.lastname,
                    loginstate: true,
                });
                this.checkuseradvice(decoded.message.playerid);

            }
            else {
                this.setState({
                    loginstate: false,
                });
            }

        }
        catch (error) {
            //////console.log();
        }

    }
    openAccout(evt, cityName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("x-menu-account-list-sidebar");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].className += " -open";
        }
    }
    async checkuseradvice(username) {


        instance.post("/api/v1/checkuseradvice", {
            User_id: username,
            page: 1,
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                console.log(res.data.message.useradvicelist);
                this.setState({
                    useradvice: res.data.message.useradvicelist
                });
            }
            else {
                // console.log(res.data);
                // this.CheckThisGoalError();
            }
        });
    }

    cancelredeempromotion = async (e, index) => {
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            Swal.fire({
                icon: 'warning',
                title: 'คุณต้องการยกเลิกการใช้โปรใช่ หรือ ไม่?',
                showCancelButton: true,
                confirmButtonText: 'Save',
            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    await instance.post("/api/v1/usercancelpromotion", {
                        User_id: Username,
                        System: Systems,
                        no: index.no,
                    }, {
                        headers: { Authorization: `Bearer ${token}` }
                    }).then(async (res) => {
                        if (res.data.status === 200) {
                            this.checkpro(null, 999);
                            Swal.fire('ดำเนินการเรียบร้อย', '', 'success')
                        }
                        else {
                            Swal.fire('ไม่สามารถดำเนินการได้ โปรดรอซักครู่', '', 'error')
                        }
                    });

                }
            })
        }

    }

    openAccoutcan(evt, cityName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("x-menu-account-list-sidebar");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].className = "x-menu-account-list-sidebar";
        }
    }

    async rechecklogin(Username, token) {

        try {
            // console.log(4);
            const search = window.location.href;
            const paramx = new URLSearchParams(search);
            // const space = search.split("/")
            const space = window.location.host
            var userhash = md5(Username);
            //  const linkaff = "https://pgline88.com/?register=" + userhash;
            const linkaff = "https://" + space + "/register?reg=" + userhash;
            instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'rechecklogin-navbar.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //  console.log(res.data);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        rankpic: res.data.tblrank.image,
                        rankname: res.data.tblrank.name,
                        linkaff: linkaff,
                        rankob: res.data.tblrank,
                        rankpoint: res.data.rankpoint,
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        tblprofile: res.data.message,
                        username: res.data.message.playerid,
                        fname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                }
                else if (res.data.status === 401) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                console.log(err)
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }

    }
    checkpro = async (e, proid) => {
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        await instance.post("/api/v1/checkpromotion", {
            userid: Username,
            System: Systems,
            Proid: proid,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            if (res.data.status === 200) {
                //console.log(res.data)
                // console.log(res.data.message.tblpromotionredeems)
                if (res.data.type === 0) {
                    this.setState({
                        prodetail: res.data.message.tblpromotions,
                    });
                }
                else if (res.data.type === 1) {
                    this.setState({
                        proderedeemlist: res.data.message.tblpromotionredeems,
                    });

                }

            }
            else if (res.data.status === 201) {
                await Swal.fire({
                    title: 'error!',
                    text: 'ท่านไม่เข้าเงื่อนใขโปรโมชั่นนี้',
                    icon: 'error',
                    confirmButtonText: 'ตกลง'
                })
                return false;
            }
            else if (res.data.status === 202) {
                await Swal.fire({
                    title: 'error!',
                    text: 'ท่านใช้โปรโมชั่นค้างไว้ สามารถเติมเงินได้เลย',
                    icon: 'error',
                    confirmButtonText: 'ตกลง'
                })
                return false;
            }

        }).catch(async (err) => {
            // console.log(err);
            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await Swal.fire({
                    title: 'Warning!',
                    text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
                window.location.href = '/';
            }
        });
    }


    validateLogout = (e) => {
        localStorage.removeItem('auth_token', '')
        this.setState({
            userid: null,
            username: null,
            password: null,
            firstname: null,
            lastname: null,
            point: null,
            loginstate: false,
        });
    }
    async recheccredit(evt) {

        evt.currentTarget.className += " fa-spin";
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        //  this.rechecklogin(Username, token);
        this.setState({
            userid: decoded.message.playerid,
            username: decoded.message.playerid,
            firstname: decoded.message.firstname,
            lastname: decoded.message.lastname,
            loginstate: true,
        });
        try {
            //  console.log(5);
            await instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'recheccredit-navbar.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //console.log(res.data);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        fname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                    var i, tablinks;
                    tablinks = document.getElementsByClassName("-btn-balance");
                    for (i = 0; i < tablinks.length; i++) {
                        tablinks[i].className = tablinks[i].className.replace(" fa-spin", "");
                    }
                }
                else if (res.data.status === 401) {
                    localStorage.clear();
                    //   console.log(res.data);
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                //    console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }
    }
    logout = (e) => {
        // e.preventDefault();
        let errors = this.validateLogout();
        if (errors === true) {
            // alert("You are successfully signed in...");
            window.location.reload()
        } else {

            this.setState({
                errors: errors,
                formSubmitted: true
            });
            window.location.reload()
        }
    }


    render() {


        const { loginstate, userid, username, point, firstname, lastname, balance, proderedeemlist, linkaff, tblprofile } = this.state

        //console.log(prodetail.length)
        return (
            <>
                <br></br>
                <br></br>
                <br></br>
                <div  >
                    <div className="x-main-content contentaff"  >
                        <section className="-contact-wrapper  m-0">
                            <div className="padding-router-view">
                                <div className="container p-0 x-margin-top-v3">
                                    <div className="row m-0 border-shadow-title">
                                        <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1">
                                            <div className="text-center bg-title-web">
                                                <div>
                                                    <div className="text-title text-white-v1 text-center">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="text-white-v1-deposit">
                                                                <a role="button"
                                                                    href='/'
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={25}
                                                                        height={25}
                                                                        fill="currentColor"
                                                                        className="bi bi-arrow-left-circle text-color-back"
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                                                                        />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                            <div className="text-white-v1-deposit">
                                                                <h5>เเนะนำเพื่อน</h5>
                                                            </div>
                                                            <div>{/**/}</div>
                                                        </div>
                                                        <hr className="mt-0" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div
                                                        className="d-flex justify-content-between p-1 text-white-v1 bg-refer-title"
                                                        style={{ width: "100%", overflowX: "scroll" }}
                                                    >
                                                        {/* this.state.tabselect == 1 ? "" : "none" */}
                                                        <div className="active-tap-hover"  >
                                                            <div
                                                                onClick={(e) => {
                                                                    this.checktab(e, 1)
                                                                }}
                                                                role="button"
                                                                className={this.state.tabselect == 1 ? "text-white-v1 p-1 rounded-pill w-width-tap  active-refer" : "text-white-v1 p-1 rounded-pill w-width-tap"}
                                                            >
                                                                <div data-v-ab1d19ba="" className="auction-width">
                                                                    <div
                                                                        data-v-ab1d19ba=""
                                                                        className="auction__body-menu auction__body--border-radius"
                                                                    >
                                                                        <div
                                                                            data-v-ab1d19ba=""
                                                                            className="tab text-white-v1"
                                                                        >
                                                                            <div
                                                                                data-v-00ef951c=""
                                                                                className="auction-pool-menu text-center"
                                                                            >
                                                                                <span className="text-size-menu-casino">
                                                                                    <b>ภาพรวม</b>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="active-tap-hover">
                                                            <div
                                                                onClick={(e) => {
                                                                    this.checktab(e, 2)
                                                                }}
                                                                role="button"
                                                                className={this.state.tabselect == 2 ? "text-white-v1 p-1 rounded-pill w-width-tap  active-refer" : "text-white-v1 p-1 rounded-pill w-width-tap"}
                                                            >
                                                                <div data-v-ab1d19ba="" className="auction-width">
                                                                    <div
                                                                        data-v-ab1d19ba=""
                                                                        className="auction__body-menu auction__body--border-radius"
                                                                    >
                                                                        <div
                                                                            data-v-ab1d19ba=""
                                                                            className="tab text-white-v1"
                                                                        >
                                                                            <div
                                                                                data-v-00ef951c=""
                                                                                className="auction-pool-menu text-center"
                                                                            >
                                                                                <span className="text-size-menu-casino">
                                                                                    <b>สมาชิก</b>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="active-tap-hover">
                                                            <div
                                                                onClick={(e) => {
                                                                    this.checktab(e, 3)
                                                                }}
                                                                role="button"
                                                                className={this.state.tabselect == 3 ? "text-white-v1 p-1 rounded-pill w-width-tap  active-refer" : "text-white-v1 p-1 rounded-pill w-width-tap"}
                                                            >
                                                                <div data-v-ab1d19ba="" className="auction-width">
                                                                    <div
                                                                        data-v-ab1d19ba=""
                                                                        className="auction__body-menu auction__body--border-radius"
                                                                    >
                                                                        <div
                                                                            data-v-ab1d19ba=""
                                                                            className="tab text-white-v1"
                                                                        >
                                                                            <div
                                                                                data-v-00ef951c=""
                                                                                className="auction-pool-menu text-center"
                                                                            >
                                                                                <span className="text-size-menu-casino">
                                                                                    <b>สรุปรายได้</b>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="active-tap-hover">
                                                            <div
                                                                onClick={(e) => {
                                                                    this.checktab(e, 4)
                                                                }}
                                                                role="button"
                                                                className={this.state.tabselect == 4 ? "text-white-v1 p-1 rounded-pill w-width-tap  active-refer" : "text-white-v1 p-1 rounded-pill w-width-tap"}
                                                            >
                                                                <div data-v-ab1d19ba="" className="auction-width">
                                                                    <div
                                                                        data-v-ab1d19ba=""
                                                                        className="auction__body-menu auction__body--border-radius"
                                                                    >
                                                                        <div
                                                                            data-v-ab1d19ba=""
                                                                            className="tab text-white-v1"
                                                                        >
                                                                            <div
                                                                                data-v-00ef951c=""
                                                                                className="auction-pool-menu text-center"
                                                                            >
                                                                                <span className="text-size-menu-casino">
                                                                                    <b>เครือข่าย</b>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-5 mt-2" style={{ display: this.state.tabselect == 1 ? "" : "none" }}>
                                                <div className="p-2 bg-profile-box-overview">
                                                    <div className="animated animatedFadeInUp fadeInUp mt-2">

                                                        <div className="bg-profile-box-overview p-2">
                                                            <div className="d-flex justify-content-between row m-0 align-items-center">
                                                                <div className="col-12 col-md-12 p-0">
                                                                    <div className="d-flex justify-content-between elementor-element-play-overview text-center bg-profile-credit-bl p-2">
                                                                        <div className="align-self-center">
                                                                            <div className="p-1 text-left">
                                                                                <div>
                                                                                    <h3 className="m-0">
                                                                                        <b className="p-1">0.00</b>
                                                                                    </h3>
                                                                                </div>
                                                                                <small>รายได้จากเครือข่าย ทั้งหมด</small>
                                                                            </div>
                                                                        </div>
                                                                        <div className="align-self-center text-right">
                                                                            <div>
                                                                                <span className="badge text-bg-dark">
                                                                                    0.00%
                                                                                </span>
                                                                            </div>
                                                                            <div>
                                                                                <span className="font-play-text">
                                                                                    จากเดือนที่แล้ว
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-2 animated animatedFadeInUp fadeInUp">
                                                            <div className="mt-2 mb-2">
                                                                <img
                                                                    className="icon-profile-title-left"
                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/81deef6a-eb5d-4bf2-221c-3b85586ca700/public"
                                                                    alt=""
                                                                />
                                                                <small className="pt-2 pb-2">
                                                                    <b>&nbsp; ลิงก์เชิญชวน</b>
                                                                </small>
                                                            </div>
                                                            <div className="bg-color-financial-my-bank bg-profile-box-overview">
                                                                <div className="d-flex justify-content-between pt-3 pb-3">
                                                                    <div>
                                                                        <small>คัดลอก</small>
                                                                    </div>
                                                                    <div>
                                                                        <small className="font-play-text">
                                                                            <b>
                                                                                <small id="copy-ShareLink">
                                                                                    {linkaff}
                                                                                </small>
                                                                                {/* <a href={() => false} style={{ cursor: "pointer" }}
                                                                                    onClick={() => navigator.clipboard.writeText(linkaff)}>
                                                                              

                                                                                </a> */}
                                                                                <a href={() => false} style={{ cursor: "pointer" }} onClick={() => this.copyCode(linkaff)} className="-copy-wrapper js-copy-to-clipboard" data-container="shareAccountProfileBankInfo" data-message="คัดลอกแล้ว!" >
                                                                                    <img
                                                                                        role="button"
                                                                                        className="icon-profile-title-left"
                                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/270b7b9f-b130-414c-b2a8-dfe9ecb9cc00/public"
                                                                                        alt=""
                                                                                    />
                                                                                </a>
                                                                            </b>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="pt-2">
                                                            <div className="row m-0">
                                                                <div className="col-12 col-md-12 p-1">
                                                                    <div className="d-flex justify-content-between financial-button">
                                                                        <div
                                                                            role="button"
                                                                            className="align-self-center p-1 credit-bl-text"
                                                                        >
                                                                            <div role="button" className="financial-button-r">
                                                                                <div className="d-flex justify-content-start">
                                                                                    <div className="align-self-center">
                                                                                        <img
                                                                                            className="logo-profile-user-w p-1"
                                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/48a22d9a-8abe-477f-0f40-57204ce17700/public"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="align-self-center">
                                                                                        <div className="username-profile">
                                                                                            <small>รายได้ที่ถอนได้</small>
                                                                                        </div>
                                                                                        <div className="username-profile">
                                                                                            <small>
                                                                                                <b className="font-aff-wid">{tblprofile.AffiliateCash}</b>
                                                                                            </small>
                                                                                            <a

                                                                                                role="button"
                                                                                                className="text-white-v1-credit-balance p-1"
                                                                                                id="icon-refresh-credit"
                                                                                            >
                                                                                                <span>
                                                                                                    <b>
                                                                                                        <svg
                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                            width={18}
                                                                                                            height={18}
                                                                                                            fill="currentColor"
                                                                                                            className="text-white-v1 bi bi-arrow-clockwise"
                                                                                                            viewBox="0 0 16 16"
                                                                                                        >
                                                                                                            <path
                                                                                                                fillRule="evenodd"
                                                                                                                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                                                                                                            />
                                                                                                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                                                                                        </svg>
                                                                                                    </b>
                                                                                                </span>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="align-self-center">
                                                                            <a role="button"
                                                                                onClick={(e) => {
                                                                                    this.opencliaminvite(e, 'bonusrankaccount')
                                                                                }}>
                                                                                <div className="bg-text-highlight pd-bl-aff">
                                                                                    <div
                                                                                        disabled="false"
                                                                                        role="button"
                                                                                        className="btn -submit js-submit-accept-term mt-0 w-100 p-2"
                                                                                    >
                                                                                        <img
                                                                                            className="wid-button p-1"
                                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/de26db72-10a7-4369-5e7e-dd07d4568300/public"
                                                                                            alt=""
                                                                                        />
                                                                                        <span>ถอนรายได้</span>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row m-0 mt-2">
                                                                <div className="col-6 p-0">
                                                                    <div className="elementor-element-play-overview p-2 m-1">
                                                                        <div className="d-flex justify-content-between text-center">
                                                                            <div className="align-self-center">
                                                                                <div className="p-1 text-left">
                                                                                    <img
                                                                                        className="icon-aff"
                                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/5e4ede40-9f30-4c50-5278-136b8e2e0900/public"
                                                                                        alt=""
                                                                                    />
                                                                                    <div>
                                                                                        <h3 className="m-0">
                                                                                            <b>0</b>
                                                                                        </h3>
                                                                                    </div>
                                                                                    <small role="button">
                                                                                        จำนวนคลิกลิงค์ทั้งหมด
                                                                                    </small>
                                                                                </div>
                                                                            </div>
                                                                            <div className="align-self-center text-right">
                                                                                <div>
                                                                                    <div>
                                                                                        <small className="font-play-text">
                                                                                            จากเดือนที่แล้ว
                                                                                        </small>
                                                                                    </div>
                                                                                    <span className="badge text-bg-dark">
                                                                                        0.00 %
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 p-0">
                                                                    <div className="elementor-element-play-overview p-2 m-1">
                                                                        <div className="d-flex justify-content-between text-center">
                                                                            <div className="align-self-center">
                                                                                <div className="p-1 text-left">
                                                                                    <img
                                                                                        className="icon-aff"
                                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a7961b16-c14d-495f-3d2f-c7ea12ab2c00/public"
                                                                                        alt=""
                                                                                    />
                                                                                    <div>
                                                                                        <h3 className="m-0">
                                                                                            <b>{this.state.useradvice.length}</b>
                                                                                        </h3>
                                                                                    </div>
                                                                                    <small role="button">
                                                                                        จำนวนสมาชิกทั้งหมด
                                                                                    </small>
                                                                                </div>
                                                                            </div>
                                                                            <div className="align-self-center text-right">
                                                                                <div>
                                                                                    <div>
                                                                                        <small className="font-play-text">
                                                                                            จากเดือนที่แล้ว
                                                                                        </small>
                                                                                    </div>
                                                                                    <span className="badge text-bg-dark">
                                                                                        0.00%
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-between pt-2 pb-2">
                                                                <div role="button" className="align-self-center">
                                                                    <img
                                                                        className="icon-profile-title-left"
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/f8270235-43e5-4c38-247d-98b9ebfbe700/public"
                                                                        alt=""
                                                                    />
                                                                    <small>
                                                                        <b>&nbsp;สมาชิกใหม่วันนี้</b>
                                                                    </small>
                                                                </div>
                                                                <div className="align-self-center">     <b>{this.state.useradvice.length}</b> คน</div>
                                                            </div>
                                                            <hr className="m-0 color-hr" />
                                                            <div className="d-flex justify-content-between pt-2 pb-2">
                                                                <div role="button" className="align-self-center" onClick={(e) => {
                                                                    this.checktab(e, 2)
                                                                }}>
                                                                    <img
                                                                        className="icon-profile-title-left"
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/f8270235-43e5-4c38-247d-98b9ebfbe700/public"
                                                                        alt=""
                                                                    />
                                                                    <small>
                                                                        <b>&nbsp;ประวัติ</b>
                                                                    </small>
                                                                </div>
                                                                <div className="align-self-center" onClick={(e) => {
                                                                    this.checktab(e, 2)
                                                                }}>
                                                                    <img
                                                                        className="goto-financial"
                                                                        role="button"
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/ad650691-9c48-4cb9-6e44-36d5d1eaf700/public"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                            <hr className="m-0 color-hr" />
                                                            <div className="d-flex justify-content-between pt-2 pb-2">
                                                                <div role="button" className="align-self-center" onClick={(e) => {
                                                                    this.checktab(e, 4)
                                                                }}>
                                                                    <img
                                                                        className="icon-profile-title-left"
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/f8270235-43e5-4c38-247d-98b9ebfbe700/public"
                                                                        alt=""
                                                                    />
                                                                    <small>
                                                                        <b>&nbsp;วิธีการสร้างเครือข่าย </b>
                                                                    </small>
                                                                </div>
                                                                <div className="align-self-center" onClick={(e) => {
                                                                    this.checktab(e, 4)
                                                                }}>
                                                                    <img
                                                                        className="goto-financial"
                                                                        role="button"
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/ad650691-9c48-4cb9-6e44-36d5d1eaf700/public"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/**/}
                                                    </div>
                                                    {/**/}
                                                    {/**/}
                                                    {/**/}
                                                </div>
                                            </div>
                                            <div className="mb-5 mt-2" style={{ display: this.state.tabselect == 2 ? "" : "none" }}>
                                                <div className="p-2 bg-profile-box-overview">
                                                    {/**/}
                                                    <div className="animated animatedFadeInUp fadeInUp">
                                                        <div className="box-number text-center">
                                                            <div>
                                                                <div className="bg-profile-box-overview p-2 mb-2">
                                                                    <div className="d-flex justify-content-between row m-0 align-items-center">
                                                                        <div className="col-12 col-md-12 p-0">
                                                                            <div className="d-flex justify-content-between elementor-element-play-overview text-center bg-profile-credit-bl p-2">
                                                                                <div className="align-self-center">
                                                                                    <div className="p-1 text-left">
                                                                                        <small>
                                                                                            <img
                                                                                                className="icon-aff"
                                                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/84f8435d-3a19-4e45-eb3c-57f582263300/public"
                                                                                                alt=""
                                                                                            />{" "}
                                                                                            ยอดแอคทีฟทั้งหมด
                                                                                        </small>
                                                                                        <div>
                                                                                            <label className="m-0 font-member-aff-all">
                                                                                                <b className="p-1">0</b>
                                                                                            </label>{" "}
                                                                                            คน
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="align-self-center text-right">
                                                                                    <div>
                                                                                        <span className="badge text-bg-dark">0.00%</span>
                                                                                    </div>
                                                                                    <div>
                                                                                        <span className="font-play-text text_aff_affiliate">
                                                                                            จากเดือนที่แล้ว
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div className="wg-content " style={{ display: (this.state.useradvice.length === 0) ? "none" : "" }}>
                                                                            <table className="table table-borderless table-striped">
                                                                                <tbody>
                                                                                    {this.state.useradvice.map((listitem, i) => (
                                                                                        <tr key={i} id={listitem.tid}>
                                                                                            <td className="-description-body-wrapper">
                                                                                                <div className="-title-wrapper">

                                                                                                    {i + 1 + "."} <span className="-title" style={{ marginLeft: "0px" }}> {listitem.playerid}
                                                                                                        {(listitem.checked !== 1) ? "" : <img src="/build/web/igame-index-lobby-wm/img/ic-coin.png"
                                                                                                            className="-ic"
                                                                                                            style={{ marginLeft: "5px", marginBottom: "3px" }}
                                                                                                            width={"20px"}
                                                                                                            alt=""
                                                                                                        />}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <span className="-title" style={{ marginLeft: "0px" }}>
                                                                                                </span>
                                                                                            </td>
                                                                                            <td className="-transaction-body-wrapper">
                                                                                                <div className="-datetime">{moment(listitem.DateCreate).format('DD/MM/YY') + " - " + moment(listitem.DateCreate).format('HH:mm') + " น."} </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between scroll-title-history-aff align-items-center mb-1 mt-1">
                                                                        <div>
                                                                            <a
                                                                                className="btn btn-outline-secondary text_aff_affiliate_tab text_aff_affiliate_tab_active"
                                                                                role="button"
                                                                            >
                                                                                วันนี้
                                                                            </a>
                                                                        </div>
                                                                        <div>
                                                                            <a
                                                                                className="btn btn-outline-secondary text_aff_affiliate_tab"
                                                                                role="button"
                                                                            >
                                                                                7 วันที่เเล้ว
                                                                            </a>
                                                                        </div>
                                                                        <div>
                                                                            <a
                                                                                className="btn btn-outline-secondary text_aff_affiliate_tab"
                                                                                role="button"
                                                                            >
                                                                                เดือนที่เเล้ว
                                                                            </a>
                                                                        </div>
                                                                        <div>
                                                                            <a
                                                                                className="btn btn-outline-secondary text_aff_affiliate_tab"
                                                                                role="button"
                                                                            >
                                                                                ทั้งหมด
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row m-0">
                                                                    <div className="col-12 col-md-6 p-1">
                                                                        <div className="elementor-element-play-overview p-2">
                                                                            <div className="d-flex justify-content-between text-center">
                                                                                <div className="align-self-center">
                                                                                    <div className="p-1 text-left">
                                                                                        <img
                                                                                            className="icon-aff"
                                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                                            alt=""
                                                                                        />{" "}
                                                                                        LV 1{" "}
                                                                                        <div>
                                                                                            <b className="m-0 font-member-aff">0</b>
                                                                                            <small className="text_aff_affiliate">คน</small>
                                                                                        </div>
                                                                                        <small className="text_aff_affiliate">
                                                                                            แนะนำสมาชิกทั้งหมด
                                                                                        </small>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="align-self-center text-right">
                                                                                    <div>
                                                                                        <div>
                                                                                            <small className="font-play-text text_aff_affiliate">
                                                                                                จากเดือนที่แล้ว
                                                                                            </small>
                                                                                        </div>
                                                                                        <span className="badge text-bg-dark">0.00%</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-6 p-1">
                                                                        <div className="elementor-element-play-overview p-2">
                                                                            <div className="d-flex justify-content-between text-center">
                                                                                <div className="align-self-center">
                                                                                    <div className="p-1 text-left">
                                                                                        <img
                                                                                            className="icon-aff"
                                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                                            alt=""
                                                                                        />{" "}
                                                                                        LV 2{" "}
                                                                                        <div>
                                                                                            <b className="m-0 font-member-aff">0</b>
                                                                                            <small className="text_aff_affiliate">คน</small>
                                                                                        </div>
                                                                                        <small className="text_aff_affiliate">
                                                                                            แนะนำสมาชิกทั้งหมด
                                                                                        </small>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="align-self-center text-right">
                                                                                    <div>
                                                                                        <div>
                                                                                            <small className="font-play-text text_aff_affiliate">
                                                                                                จากเดือนที่แล้ว
                                                                                            </small>
                                                                                        </div>
                                                                                        <span className="badge text-bg-dark">0.00%</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-6 p-1">
                                                                        <div className="elementor-element-play-overview p-2">
                                                                            <div className="d-flex justify-content-between text-center">
                                                                                <div className="align-self-center">
                                                                                    <div className="p-1 text-left">
                                                                                        <img
                                                                                            className="icon-aff"
                                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                                            alt=""
                                                                                        />{" "}
                                                                                        LV 3{" "}
                                                                                        <div>
                                                                                            <b className="m-0 font-member-aff">0</b>
                                                                                            <small className="text_aff_affiliate">คน</small>
                                                                                        </div>
                                                                                        <small className="text_aff_affiliate">
                                                                                            แนะนำสมาชิกทั้งหมด
                                                                                        </small>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="align-self-center text-right">
                                                                                    <div>
                                                                                        <div>
                                                                                            <small className="font-play-text text_aff_affiliate">
                                                                                                จากเดือนที่แล้ว
                                                                                            </small>
                                                                                        </div>
                                                                                        <span className="badge text-bg-dark">0.00%</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-6 p-1">
                                                                        <div className="elementor-element-play-overview p-2">
                                                                            <div className="d-flex justify-content-between text-center">
                                                                                <div className="align-self-center">
                                                                                    <div className="p-1 text-left">
                                                                                        <img
                                                                                            className="icon-aff"
                                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                                            alt=""
                                                                                        />{" "}
                                                                                        LV 4{" "}
                                                                                        <div>
                                                                                            <b className="m-0 font-member-aff">0</b>
                                                                                            <small className="text_aff_affiliate">คน</small>
                                                                                        </div>
                                                                                        <small className="text_aff_affiliate">
                                                                                            แนะนำสมาชิกทั้งหมด
                                                                                        </small>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="align-self-center text-right">
                                                                                    <div>
                                                                                        <div>
                                                                                            <small className="font-play-text text_aff_affiliate">
                                                                                                จากเดือนที่แล้ว
                                                                                            </small>
                                                                                        </div>
                                                                                        <span className="badge text-bg-dark">0.00%</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-6 p-1">
                                                                        <div className="elementor-element-play-overview p-2">
                                                                            <div className="d-flex justify-content-between text-center">
                                                                                <div className="align-self-center">
                                                                                    <div className="p-1 text-left">
                                                                                        <img
                                                                                            className="icon-aff"
                                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                                            alt=""
                                                                                        />{" "}
                                                                                        LV 5{" "}
                                                                                        <div>
                                                                                            <b className="m-0 font-member-aff">0</b>
                                                                                            <small className="text_aff_affiliate">คน</small>
                                                                                        </div>
                                                                                        <small className="text_aff_affiliate">
                                                                                            แนะนำสมาชิกทั้งหมด
                                                                                        </small>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="align-self-center text-right">
                                                                                    <div>
                                                                                        <div>
                                                                                            <small className="font-play-text text_aff_affiliate">
                                                                                                จากเดือนที่แล้ว
                                                                                            </small>
                                                                                        </div>
                                                                                        <span className="badge text-bg-dark">0.00%</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="bg-profile-box-overview p-2 mb-2 mt-2">
                                                                    <div className="d-flex justify-content-between row m-0 align-items-center">
                                                                        <div className="col-12 col-md-12 p-0">
                                                                            <div className="d-flex justify-content-between elementor-element-play-overview text-center bg-profile-credit-bl p-2">
                                                                                <div className="align-self-center">
                                                                                    <div className="p-1 text-left">
                                                                                        <small className="text_aff_affiliate">
                                                                                            ยอดการเล่นทุกประเภท
                                                                                        </small>
                                                                                        <div>
                                                                                            <label className="m-0">
                                                                                                <b className="p-1">0 คน</b>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <table className="table">
                                                                        <tbody>
                                                                            <tr className="text-center cl-mod-1">
                                                                                <td className="p-1-aff" style={{ width: 50 }}>
                                                                                    <img
                                                                                        className="icon-aff-member"
                                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                                        alt=""
                                                                                    />
                                                                                </td>
                                                                                <td className="text-left p-1-aff text_aff_affiliate">
                                                                                    <b>ลำดับที่ 1</b>
                                                                                </td>
                                                                                <td className="text-left p-1-aff text_aff_affiliate">0</td>
                                                                                <td className="p-1-aff text_aff_affiliate">คน</td>
                                                                            </tr>
                                                                            <tr className="text-center cl-mod-2">
                                                                                <td className="p-1-aff" style={{ width: 50 }}>
                                                                                    <img
                                                                                        className="icon-aff-member"
                                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                                        alt=""
                                                                                    />
                                                                                </td>
                                                                                <td className="text-left p-1-aff text_aff_affiliate">
                                                                                    <b>ลำดับที่ 2</b>
                                                                                </td>
                                                                                <td className="text-left p-1-aff text_aff_affiliate">0</td>
                                                                                <td className="p-1-aff text_aff_affiliate">คน</td>
                                                                            </tr>
                                                                            <tr className="text-center cl-mod-1">
                                                                                <td className="p-1-aff" style={{ width: 50 }}>
                                                                                    <img
                                                                                        className="icon-aff-member"
                                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                                        alt=""
                                                                                    />
                                                                                </td>
                                                                                <td className="text-left p-1-aff text_aff_affiliate">
                                                                                    <b>ลำดับที่ 3</b>
                                                                                </td>
                                                                                <td className="text-left p-1-aff text_aff_affiliate">0</td>
                                                                                <td className="p-1-aff text_aff_affiliate">คน</td>
                                                                            </tr>
                                                                            <tr className="text-center cl-mod-2">
                                                                                <td className="p-1-aff" style={{ width: 50 }}>
                                                                                    <img
                                                                                        className="icon-aff-member"
                                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                                        alt=""
                                                                                    />
                                                                                </td>
                                                                                <td className="text-left p-1-aff text_aff_affiliate">
                                                                                    <b>ลำดับที่ 4</b>
                                                                                </td>
                                                                                <td className="text-left p-1-aff text_aff_affiliate">0</td>
                                                                                <td className="p-1-aff text_aff_affiliate">คน</td>
                                                                            </tr>
                                                                            <tr className="text-center cl-mod-1">
                                                                                <td className="p-1-aff" style={{ width: 50 }}>
                                                                                    <img
                                                                                        className="icon-aff-member"
                                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                                        alt=""
                                                                                    />
                                                                                </td>
                                                                                <td className="text-left p-1-aff text_aff_affiliate">
                                                                                    <b>ลำดับที่ 5</b>
                                                                                </td>
                                                                                <td className="text-left p-1-aff text_aff_affiliate">0</td>
                                                                                <td className="p-1-aff text_aff_affiliate">คน</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className="d-flex justify-content-between pt-2 pb-2">
                                                                    <div role="button" className="align-self-end">
                                                                        <small>
                                                                            <b>&nbsp;รายละเอียกเครือข่าย</b>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                                <div className="bg-profile-box-overview p-2 mb-2">
                                                                    <table className="table">
                                                                        <thead className="table-dark-history">
                                                                            <tr className="bg-table-dark-history-title">
                                                                                <td className="credit-bl-text text_aff_affiliate">
                                                                                    <small>วันที่</small>
                                                                                </td>
                                                                                <td className="credit-bl-text text_aff_affiliate">
                                                                                    <small>ยอดถอน</small>
                                                                                </td>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr className="bg-table-dark-history">
                                                                                <td colSpan={2} className="text-white-v1 text-center">
                                                                                    <div>
                                                                                        <div className="bg-profile-box-overview p-2 text-center animated animatedFadeInUp fadeInUp">
                                                                                            <b>
                                                                                                <img
                                                                                                    className="load-history"
                                                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e4ac87bf-3687-4f37-c96e-6ad9e797bf00/public"
                                                                                                    alt=""
                                                                                                />
                                                                                                <div className="not-history font-history-menu-text p-2">
                                                                                                    <small>
                                                                                                        <b>ไม่พบรายการ...</b>
                                                                                                    </small>
                                                                                                </div>
                                                                                            </b>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/**/}
                                                    {/**/}
                                                </div>
                                            </div>
                                            <div className="mb-5 mt-2" style={{ display: this.state.tabselect == 3 ? "" : "none" }}>
                                                <div className="p-2 bg-profile-box-overview">
                                                    {/**/}
                                                    {/**/}
                                                    <div className="animated animatedFadeInUp fadeInUp">
                                                        <div className="align-self-center">
                                                            <div className="p-1 text-left">
                                                                <label>สรุปรายได้ ทั้งหมด</label>
                                                            </div>
                                                        </div>
                                                        <div className="bg-profile-box-overview p-2 mb-2">
                                                            <div className="d-flex justify-content-between row m-0 align-items-center">
                                                                <div className="col-12 col-md-12 p-0">
                                                                    <div className="d-flex justify-content-between elementor-element-play-overview text-center bg-profile-credit-bl p-2">
                                                                        <div className="align-self-center">
                                                                            <div className="p-1 text-left">
                                                                                <div>
                                                                                    <small className="text_aff_affiliate">
                                                                                        รายได้จากเครือข่าย ทั้งหมด
                                                                                    </small>
                                                                                    <h3 className="m-0">
                                                                                        <b className="p-1">0.00</b>
                                                                                    </h3>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="align-self-center text-right">
                                                                            <div>
                                                                                <span className="badge text-bg-dark">0.00%</span>
                                                                            </div>
                                                                            <div>
                                                                                <span className="font-play-text text_aff_affiliate">
                                                                                    จากเดือนที่แล้ว
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="pt-2">
                                                                <div className="row m-0">
                                                                    <div className="col-12 col-md-12 p-1">
                                                                        <div className="d-flex justify-content-between financial-button">
                                                                            <div
                                                                                role="button"
                                                                                className="align-self-center p-1 credit-bl-text"
                                                                            >
                                                                                <div role="button" className="financial-button-r">
                                                                                    <div className="d-flex justify-content-start">
                                                                                        <div className="align-self-center">
                                                                                            <img
                                                                                                className="logo-profile-user-w p-1"
                                                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/48a22d9a-8abe-477f-0f40-57204ce17700/public"
                                                                                                alt=""
                                                                                            />
                                                                                        </div>
                                                                                        <div className="align-self-center">
                                                                                            <div className="username-profile">
                                                                                                <small>รายได้ที่ถอนได้</small>
                                                                                            </div>
                                                                                            <div className="username-profile">
                                                                                                <small>
                                                                                                    <b className="font-aff-wid">0.00</b>
                                                                                                </small>
                                                                                                <a
                                                                                                    role="button"
                                                                                                    className="text-white-v1-credit-balance p-1"
                                                                                                    id="icon-refresh-credit"
                                                                                                >
                                                                                                    <span>
                                                                                                        <b>
                                                                                                            <svg
                                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                                width={18}
                                                                                                                height={18}
                                                                                                                fill="currentColor"
                                                                                                                className="text-white-v1 bi bi-arrow-clockwise"
                                                                                                                viewBox="0 0 16 16"
                                                                                                            >
                                                                                                                <path
                                                                                                                    fillRule="evenodd"
                                                                                                                    d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                                                                                                                />
                                                                                                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                                                                                            </svg>
                                                                                                        </b>
                                                                                                    </span>
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="bg-profile-box-overview p-2 mb-2">
                                                            <div className="d-flex justify-content-between row m-0 align-items-center">
                                                                <div className="col-12 col-md-12 p-0">
                                                                    <div className="d-flex justify-content-between elementor-element-play-overview text-center bg-profile-credit-bl">
                                                                        <div className="align-self-center">
                                                                            <div className="p-1 text-left">
                                                                                <div>
                                                                                    <img
                                                                                        className="icon-aff-member"
                                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/db83e83e-f021-468d-995c-c10ebf09fb00/public"
                                                                                        alt=""
                                                                                    />
                                                                                    <small className="text_aff_affiliate">
                                                                                        ค้นหาที่มารายได้
                                                                                    </small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row text-center m-0">
                                                                <div className="col-12 col-md-6 p-1">
                                                                    <select
                                                                        className="form-control option-search-aff"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        <option value="all">ทุกเลเวล</option>
                                                                        <option value="lv1">level 1</option>
                                                                        <option value="lv2">level 2</option>
                                                                        <option value="lv3">level 3</option>
                                                                        <option value="lv5">level 5</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-12 col-md-6 p-1">
                                                                    <select
                                                                        className="form-control option-search-aff"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        <option value="all">ทุกประเภท</option>
                                                                        <option value="slot">สล็อต-slot</option>
                                                                        <option value="casino">คาสิโน-casino</option>
                                                                        <option value="winloss">ยอดเสีย-winloss</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-12 col-md-12 p-1 text-left">
                                                                    <div className="input-group">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control bg-income-summary text-center-s-date font-play-text"
                                                                            placeholder="วันนี้ — วันนี้"
                                                                            aria-label="วันนี้ — วันนี้"
                                                                            disabled=""
                                                                            aria-describedby="basic-addon2"
                                                                        />
                                                                        <span
                                                                            role="button"
                                                                            className="input-group-text"
                                                                            id="basic-addon2"
                                                                        >
                                                                            {/* <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width={16}
                                                                                height={16}
                                                                                fill="currentColor"
                                                                                className="bi bi-calendar"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                                                            </svg> */}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="row text-center">
                                                                        <div className="col-12 col-md-6 p-1">
                                                                            <div role="button" className="affiliate-aff-search p-2">
                                                                                <label className="font-play-text">ล้างค่า</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-md-6 p-1">
                                                                            <div role="button" className="affiliate-aff-search-submit p-2">
                                                                                <label>ค้นหา</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="credit-bl-text pb-2">
                                                            <span>
                                                                <small>คุณกำลังดูช่วงเวลา &nbsp; </small>
                                                            </span>
                                                        </div>
                                                        <div className="bg-profile-box-overview p-2 mb-2">
                                                            <div className="d-flex justify-content-between pb-2">
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    <div className="p-2">
                                                                        <img
                                                                            className="icon-aff-member"
                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            <h5 className="credit-bl-text">
                                                                                <b>ระดับที่ 1</b>
                                                                            </h5>
                                                                        </div>
                                                                        <div>
                                                                            <small className="text_aff_affiliate">
                                                                                รายละเอียดการทำรายได้
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <table className="table mb-0">
                                                                <thead className="table-dark-history">
                                                                    <tr className="bg-table-dark-history-title">
                                                                        <td className="credit-bl-text text_aff_affiliate text-center">
                                                                            <small>ประเภท</small>
                                                                        </td>
                                                                        <td className="credit-bl-text text_aff_affiliate text-center">
                                                                            <small>ยอดแทง</small>
                                                                        </td>
                                                                        <td className="credit-bl-text text_aff_affiliate text-center">
                                                                            <small>รายได้</small>
                                                                        </td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr className="bg-table-dark-history">
                                                                        <td colSpan={3} className="text-white-v1 text-center">
                                                                            <div>
                                                                                <div className="bg-profile-box-overview p-2 text-center animated animatedFadeInUp fadeInUp">
                                                                                    <b>
                                                                                        <img
                                                                                            className="load-history"
                                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e4ac87bf-3687-4f37-c96e-6ad9e797bf00/public"
                                                                                            alt=""
                                                                                        />
                                                                                        <div className="not-history font-history-menu-text p-2">
                                                                                            <small>
                                                                                                <b>ไม่พบรายการ...</b>
                                                                                            </small>
                                                                                        </div>
                                                                                    </b>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        {/* <div className="bg-profile-box-overview p-2 mb-2">
                                                            <div className="d-flex justify-content-between pb-2">
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    <div className="p-2">
                                                                        <img
                                                                            className="icon-aff-member"
                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            <h5 className="credit-bl-text">
                                                                                <b>ระดับที่ 2</b>
                                                                            </h5>
                                                                        </div>
                                                                        <div>
                                                                            <small className="text_aff_affiliate">
                                                                                รายละเอียดการทำรายได้
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <table className="table mb-0">
                                                                <thead className="table-dark-history">
                                                                    <tr className="bg-table-dark-history-title">
                                                                        <td className="credit-bl-text text_aff_affiliate text-center">
                                                                            <small>ประเภท</small>
                                                                        </td>
                                                                        <td className="credit-bl-text text_aff_affiliate text-center">
                                                                            <small>ยอดแทง</small>
                                                                        </td>
                                                                        <td className="credit-bl-text text_aff_affiliate text-center">
                                                                            <small>รายได้</small>
                                                                        </td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr className="bg-table-dark-history">
                                                                        <td colSpan={3} className="text-white-v1 text-center">
                                                                            <div>
                                                                                <div className="bg-profile-box-overview p-2 text-center animated animatedFadeInUp fadeInUp">
                                                                                    <b>
                                                                                        <img
                                                                                            className="load-history"
                                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e4ac87bf-3687-4f37-c96e-6ad9e797bf00/public"
                                                                                            alt=""
                                                                                        />
                                                                                        <div className="not-history font-history-menu-text p-2">
                                                                                            <small>
                                                                                                <b>ไม่พบรายการ...</b>
                                                                                            </small>
                                                                                        </div>
                                                                                    </b>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="bg-profile-box-overview p-2 mb-2">
                                                            <div className="d-flex justify-content-between pb-2">
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    <div className="p-2">
                                                                        <img
                                                                            className="icon-aff-member"
                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            <h5 className="credit-bl-text">
                                                                                <b>ระดับที่ 3</b>
                                                                            </h5>
                                                                        </div>
                                                                        <div>
                                                                            <small className="text_aff_affiliate">
                                                                                รายละเอียดการทำรายได้
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <table className="table mb-0">
                                                                <thead className="table-dark-history">
                                                                    <tr className="bg-table-dark-history-title">
                                                                        <td className="credit-bl-text text_aff_affiliate text-center">
                                                                            <small>ประเภท</small>
                                                                        </td>
                                                                        <td className="credit-bl-text text_aff_affiliate text-center">
                                                                            <small>ยอดแทง</small>
                                                                        </td>
                                                                        <td className="credit-bl-text text_aff_affiliate text-center">
                                                                            <small>รายได้</small>
                                                                        </td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr className="bg-table-dark-history">
                                                                        <td colSpan={3} className="text-white-v1 text-center">
                                                                            <div>
                                                                                <div className="bg-profile-box-overview p-2 text-center animated animatedFadeInUp fadeInUp">
                                                                                    <b>
                                                                                        <img
                                                                                            className="load-history"
                                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e4ac87bf-3687-4f37-c96e-6ad9e797bf00/public"
                                                                                            alt=""
                                                                                        />
                                                                                        <div className="not-history font-history-menu-text p-2">
                                                                                            <small>
                                                                                                <b>ไม่พบรายการ...</b>
                                                                                            </small>
                                                                                        </div>
                                                                                    </b>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="bg-profile-box-overview p-2 mb-2">
                                                            <div className="d-flex justify-content-between pb-2">
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    <div className="p-2">
                                                                        <img
                                                                            className="icon-aff-member"
                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            <h5 className="credit-bl-text">
                                                                                <b>ระดับที่ 4</b>
                                                                            </h5>
                                                                        </div>
                                                                        <div>
                                                                            <small className="text_aff_affiliate">
                                                                                รายละเอียดการทำรายได้
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <table className="table mb-0">
                                                                <thead className="table-dark-history">
                                                                    <tr className="bg-table-dark-history-title">
                                                                        <td className="credit-bl-text text_aff_affiliate text-center">
                                                                            <small>ประเภท</small>
                                                                        </td>
                                                                        <td className="credit-bl-text text_aff_affiliate text-center">
                                                                            <small>ยอดแทง</small>
                                                                        </td>
                                                                        <td className="credit-bl-text text_aff_affiliate text-center">
                                                                            <small>รายได้</small>
                                                                        </td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr className="bg-table-dark-history">
                                                                        <td colSpan={3} className="text-white-v1 text-center">
                                                                            <div>
                                                                                <div className="bg-profile-box-overview p-2 text-center animated animatedFadeInUp fadeInUp">
                                                                                    <b>
                                                                                        <img
                                                                                            className="load-history"
                                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e4ac87bf-3687-4f37-c96e-6ad9e797bf00/public"
                                                                                            alt=""
                                                                                        />
                                                                                        <div className="not-history font-history-menu-text p-2">
                                                                                            <small>
                                                                                                <b>ไม่พบรายการ...</b>
                                                                                            </small>
                                                                                        </div>
                                                                                    </b>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="bg-profile-box-overview p-2 mb-2">
                                                            <div className="d-flex justify-content-between pb-2">
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    <div className="p-2">
                                                                        <img
                                                                            className="icon-aff-member"
                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/57836a6a-99c5-4a6f-cad8-32de65521a00/public"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            <h5 className="credit-bl-text">
                                                                                <b>ระดับที่ 5</b>
                                                                            </h5>
                                                                        </div>
                                                                        <div>
                                                                            <small className="text_aff_affiliate">
                                                                                รายละเอียดการทำรายได้
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <table className="table mb-0">
                                                                <thead className="table-dark-history">
                                                                    <tr className="bg-table-dark-history-title">
                                                                        <td className="credit-bl-text text_aff_affiliate text-center">
                                                                            <small>ประเภท</small>
                                                                        </td>
                                                                        <td className="credit-bl-text text_aff_affiliate text-center">
                                                                            <small>ยอดแทง</small>
                                                                        </td>
                                                                        <td className="credit-bl-text text_aff_affiliate text-center">
                                                                            <small>รายได้</small>
                                                                        </td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr className="bg-table-dark-history">
                                                                        <td colSpan={3} className="text-white-v1 text-center">
                                                                            <div>
                                                                                <div className="bg-profile-box-overview p-2 text-center animated animatedFadeInUp fadeInUp">
                                                                                    <b>
                                                                                        <img
                                                                                            className="load-history"
                                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e4ac87bf-3687-4f37-c96e-6ad9e797bf00/public"
                                                                                            alt=""
                                                                                        />
                                                                                        <div className="not-history font-history-menu-text p-2">
                                                                                            <small>
                                                                                                <b>ไม่พบรายการ...</b>
                                                                                            </small>
                                                                                        </div>
                                                                                    </b>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div> */}
                                                    </div>
                                                    {/**/}
                                                </div>
                                            </div>
                                            <div className="mb-5 mt-2" style={{ display: this.state.tabselect == 4 ? "" : "none" }}>
                                                <div className="p-2 bg-profile-box-overview">
                                                    {/**/}
                                                    {/**/}
                                                    {/**/}
                                                    <div className="animated animatedFadeInUp fadeInUp text-center">
                                                        <div className="bg-profile-box p-2">
                                                            <div>
                                                                <div className="p-2 align-self-center">
                                                                    <div className="username-profile">
                                                                        <b>รวยง่ายๆ กับ เรา</b>
                                                                    </div>
                                                                    <div className="username-profile">
                                                                        <b>แค่แชร์ลิงก์ก็สร้างรายได้</b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-between row m-0 align-items-center">
                                                                <div className="col-12 col-md-12 p-0">
                                                                    <section className="carousel" dir="ltr" aria-label="Gallery">

                                                                    </section>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </>
        );
    }
}
export default App;
